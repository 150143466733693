// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n}\n\n.PWA_PWA__d42Nw {\n  display: flex;\n  flex-direction: column;\n}\n.PWA_PWA__d42Nw table, .PWA_PWA__d42Nw td {\n  border: 1px solid;\n}\n.PWA_PWA_Preamble__-Pl6T {\n  text-align: left;\n  align-self: self-end;\n}\n.PWA_PWA_Header__elSNc {\n  text-transform: uppercase;\n  color: #4d5766;\n}\n.PWA_PWA_Content__zt9zy {\n  text-align: justify;\n  text-indent: 10px;\n  line-height: 1.4;\n}", "",{"version":3,"sources":["webpack://./src/Pages/PWA/PWA.module.scss"],"names":[],"mappings":"AAAA;EACI,SAAA;EACA,UAAA;EACA,sBAAA;AACJ;;AAEA;EACI,aAAA;EACA,sBAAA;AACJ;AACI;EACI,iBAAA;AACR;AAEI;EACI,gBAAA;EACA,oBAAA;AAAR;AAEI;EACI,yBAAA;EACA,cAAA;AAAR;AAEI;EACI,mBAAA;EACA,iBAAA;EACA,gBAAA;AAAR","sourcesContent":["* {\n    margin: 0;\n    padding: 0;\n    box-sizing: border-box;\n}\n\n.PWA {\n    display: flex;\n    flex-direction: column;\n\n    table, td {\n        border: 1px solid;\n      }\n\n    &_Preamble {\n        text-align: left;\n        align-self: self-end;\n    }\n    &_Header {\n        text-transform: uppercase;\n        color: #4d5766;\n    }\n    &_Content {\n        text-align: justify;\n        text-indent: 10px;\n        line-height: 1.4;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PWA": "PWA_PWA__d42Nw",
	"PWA_Preamble": "PWA_PWA_Preamble__-Pl6T",
	"PWA_Header": "PWA_PWA_Header__elSNc",
	"PWA_Content": "PWA_PWA_Content__zt9zy"
};
export default ___CSS_LOADER_EXPORT___;
