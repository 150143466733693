// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".studentAccardion_accardionWrapper__Aqj-k {\n  margin-top: 24px;\n  padding: 0 72px;\n  width: 100%;\n  margin: 0 auto;\n}\n@media only screen and (min-width: 320px) and (max-width: 375px) {\n  .studentAccardion_accardionWrapper__Aqj-k {\n    padding: 0;\n  }\n}\n@media only screen and (min-width: 375px) and (max-width: 480px) {\n  .studentAccardion_accardionWrapper__Aqj-k {\n    padding: 0;\n  }\n}", "",{"version":3,"sources":["webpack://./src/components/StudentAccardion/studentAccardion.module.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,eAAA;EACA,WAAA;EACA,cAAA;AACJ;AACI;EANJ;IAOQ,UAAA;EAEN;AACF;AAEI;EAZJ;IAaQ,UAAA;EACN;AACF","sourcesContent":[".accardionWrapper {\n    margin-top: 24px;\n    padding: 0 72px;\n    width: 100%;\n    margin: 0 auto;\n    \n    @media only screen and (min-width : 320px) and (max-width : 375px) {\n        padding: 0;\n      \n    \n        \n    }\n    @media only screen and (min-width : 375px) and (max-width : 480px) {\n        padding: 0;\n        \n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"accardionWrapper": "studentAccardion_accardionWrapper__Aqj-k"
};
export default ___CSS_LOADER_EXPORT___;
