import { checkedIcon, noCheckedIcon, dotBlockIcon } from '../constants/svgIcons'

export const checkedIconPath = [{ d: checkedIcon, fill: 'currentColor' }]

export const noCheckedIconPath = [{ d: noCheckedIcon, fill: 'currentColor' }]

export const doBlockIconPath = [
  { d: dotBlockIcon.d1, fill: '#9CA3AF' },
  { d: dotBlockIcon.d2, fill: '#9CA3AF' },
  { d: dotBlockIcon.d3, fill: '#9CA3AF' },
  { d: dotBlockIcon.d4, fill: '#9CA3AF' },
  { d: dotBlockIcon.d5, fill: '#9CA3AF' },
  { d: dotBlockIcon.d6, fill: '#9CA3AF' },
]
