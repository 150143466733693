import styles from './Agreement.module.scss';

export const Agreement = () => {
  return (
      <section className={styles.agreement}>
        <p className={styles.agreement__preamble}>
          УТВЕРЖДЕНО
          <br/>
          в новой редакции
          <br/>
          приказ №41 от 19.09.2023г.
          <br/>
          директор ООО «Оверван»
          <br/>
          Т.В.Андреева
        </p>
        <h1 className={styles.agreement__header}>
          публичный договор возмездного оказания услуг
        </h1>
        <section className={styles.agreement__intro}>
          <p>
            Общество с ограниченной ответственностью «Оверван», именуемое в дальнейшем «Исполнитель», в лице директора Андреевой Татьяны Викторовны, действующего на основании Устава, заключает настоящий Публичный договор возмездного оказания услуг (далее по тексту – Договор) с любым физическим лицом, именуемым в дальнейшем «Заказчик».
          </p>
          <p>
            Договор является соглашением о предоставлении на возмездной основе онлайн-доступа к программному обеспечению Исполнителя «Платформа справочных материалов «Oversсhool» (далее – ПО) посредством сайта https://overone.by/, являющегося частью ПО, в том числе к Модулям (Блокам), заключаемым путем публичной оферты, и регламентирует порядок оказания таких услуг и обязательства сторон.
          </p>
          <p>
            Текст настоящего Договора размещается Исполнителем в сети Интернет на веб-сайте по адресу: https://overone.by/.
          </p>
          <p>
            Стороны, руководствуясь статьей 396 Гражданского кодекса Республики Беларусь, заключили настоящий Договор о нижеследующем:
          </p>
        </section>
        <ol className={styles.agreement__items}>
          <li className={styles.agreement__item}>
            <h3 className={styles.agreement__header}>
              Термины и определения, используемые в Договоре.
            </h3>
            <ol className={styles.agreement__items}>
              <li className={styles.agreement__item}>
                Сайт – веб-сайт https://overone.by/, принадлежащий Исполнителю, являющийся частью программного обеспечения «Платформа справочных материалов «Oversсhool».
              </li>
              <li className={styles.agreement__item}>
                Модуль – совокупность справочных и практических материалов, объединенных единой темой и направленных на приобретение Заказчиком знаний и навыков по этой теме.
              </li>
              <li className={styles.agreement__item}>
                Блок– составная часть Модуля.
              </li>
              <li className={styles.agreement__item}>
                Номер телефона – указанный Заказчиком номер мобильного телефона, по которому осуществляет коммуникация между сторонами.
              </li>
              <li className={styles.agreement__item}>
                Адрес электронной почты – указанный Заказчиком адрес электронной почты, посредством которой Заказчик получает доступ к Модулю, и по которому возможно осуществление связи с Заказчиком.
              </li>
              <li className={styles.agreement__item}>
                Мессенджер – специальное приложение или программа (Telegram, Viber, WhatsApp), установленная на телефон, компьютер, используемая для обмена текстовыми сообщениями, фото, видео, документами, совершения звонков при помощи аудио или видеосвязи. Коммуникации между Исполнителем и Заказчиком могут осуществляется с использованием Мессенджера.
              </li>
              <li className={styles.agreement__item}>
                Рекламная акция – мероприятие, направленное для привлечения внимания существующих или потенциальных Заказчиков.
              </li>
              <li className={styles.agreement__item}>
                Прейскурант - действующий перечень оказываемых Исполнителем услуг с ценами, размещенный на Сайте.
              </li>
              <li className={styles.agreement__item}>
                Скидка – сумма, на которую снижается стоимость Модуля. Информация о размерах Скидки предоставляется Заказчику по следующей ссылке: https://overone.by/sale/70.
              </li>
              <li className={styles.agreement__item}>
                Счет – счет-фактура, в котором определяется стоимость выбранной Заказчиком услуги, порядок ее оплаты, размер предоставляемой Скидки.
              </li>
              <li className={styles.agreement__item}>
                Рассрочка платежа – согласованный сторонами способ оплаты, при котором оплата за услуги производится в соответствии с графиком платежей, указанным в Счете.
              </li>
            </ol>
          </li>
          <li className={styles.agreement__item}>
            <h3 className={styles.agreement__header}>
              Предмет договора. Порядок заключения договора.
            </h3>
            <ol className={styles.agreement__items}>
              <li className={styles.agreement__item}>
                В соответствии с настоящим Договором Исполнитель обязуется предоставить Заказчику онлайн- доступ к программному обеспечению Исполнителя «Платформа справочных материалов «Oversсhool» посредством сайта https://overone.by/, являющегося частью программного обеспечения, в частности к материалам Модуля (далее - доступ к Модулю), а Заказчик обязуется оплатить Исполнителю вознаграждение.
              </li>
              <li className={styles.agreement__item}>
                Настоящий Договор является публичным договором (статья 396 Гражданского кодекса Республики Беларусь), в соответствии с которым Исполнитель принимает на себя обязательство по оказанию услуг в отношении неопределенного круга лиц (Заказчиков), обратившихся за их оказанием.
              </li>
              <li className={styles.agreement__item}>
                Размещение текста настоящего Договора на Сайте является публичным предложением (офертой) Исполнителя, адресованным неопределенному кругу лиц заключить настоящий Договор (п.2. ст.407 Гражданского Кодекса Республики Беларусь).
              </li>
              <li className={styles.agreement__item}>
                Заключение Договора производится путем присоединения Заказчика к Договору, то есть посредством принятия (акцепта) Заказчиком условий Договора в целом, без каких-либо условий, изъятий и оговорок (ст. 398 Гражданского кодекса Республики Беларусь).
              </li>
              <li className={styles.agreement__item}>
                Фактом принятия (акцепта) Заказчиком условий настоящего Договора является оплата Заказчиком заказанных им услуг в порядке и на условиях, определенных настоящим Договором. В случае оплаты Заказчиком заказанных им услуг с использованием кредитных средств, предоставленных соответствующим банком, фактом принятия (акцепта) Заказчиком условий настоящего Договора является заключение Заказчиком соответствующего договора с банком для оплаты услуг Исполнителя (п. 3 ст. 408 Гражданского кодекса Республики Беларусь). В случае предоставления рассрочки платежа фактом принятия (акцепта) Заказчиком условий настоящего Договора будет признаваться внесение первого платежа в соответствии с согласованными сторонами условиями.
              </li>
              <li className={styles.agreement__item}>
                Настоящий Договор, при условии соблюдения порядка его акцепта, считается заключенным в простой письменной форме (пункты 2, 3 статьи 404 и пункт 3 статьи 408 Гражданского кодекса Республики Беларусь). 
              </li>
              <li className={styles.agreement__item}>
                Местом заключения договора стороны признают местонахождение Исполнителя.
              </li>
            </ol>
          </li>
          <li className={styles.agreement__item}>
            <h3 className={styles.agreement__header}>
              Порядок оказания услуг
            </h3>
            <ol className={styles.agreement__items}>
              <li className={styles.agreement__item}>
                С помощью Сайта или иным образом Заказчик выбирает Модуль. После получения информации о выборе услуги, сотрудник Исполнителя направляет Заказчику счет на оплату Модуля. После зачисления оплаты (части оплаты, в случае оформления рассрочки платежа) на расчетный счет Исполнителя, сотрудник Исполнителя посредством электронной почты предоставляет Заказчику доступ к Модулю либо  Блоку (составной части Модуля) пропорционально оплаченной стоимости.
              </li>
              <li className={styles.agreement__item}>
                Датой оказания услуг считается дата предоставления Заказчику онлайн-доступа к материалам Модуля. Таким образом, с момента предоставления онлайн-доступа к соответствующему Модулю, или Блоку (составной части Модуля), в случае  рассрочки платежа, Услуги считаются оказанными Заказчику. Исполнитель не позднее 5 –го числа месяца , следующего за месяцем оказания услуг, составляет Акт оказанных услуг в одностороннем порядке без направления Заказчику, в соответствии с действующим законодательством Республики Беларусь.
              </li>
              <li className={styles.agreement__item}>
                При  надлежащем соблюдении условий настоящего Договора, Заказчик вправе использовать предоставленный Исполнителем доступ к материалам Модуля на протяжении 1 (одного) месяца.
              </li>
              <li className={styles.agreement__item}>
                При одностороннем отказе Заказчика от предоставленного доступа к Модулю считается, что невозможность исполнения Договора и оказания услуг возникла по вине Заказчика. 
              </li>
              <li className={styles.agreement__item}>
                Если доступ к выбранному Модулю был оплачен с использованием кредитных средств, Исполнитель производит возврат денежных средств в адрес соответствующего банка за вычетом сумм комиссий, удержанных банком с Исполнителя. Исполнитель не несет ответственности за оплату и не возмещает Заказчику сумму начисленных процентов за пользование денежными средствами, подлежащих оплате Заказчиком банку в соответствии с заключенным Заказчиком с банком договором. 
              </li>
              <li className={styles.agreement__item}>
                В случае если Заказчик в одностороннем порядке отказывается от услуг по Договору до предоставления Исполнителем доступа к выбранному Заказчиком Модулю, оплаченному с привлечением кредитных средств, Исполнитель производит возврат денежных средств в адрес банка-партнера, за вычетом сумм комиссий, удержанных банком-партнером с Исполнителя. Исполнитель не несет ответственности за оплату и не возмещает Заказчику сумму начисленных процентов за пользование денежными средствами, подлежащих оплате Заказчиком банку-партнеру в соответствии с заключенным Заказчиком с банком-партнером договором.
              </li>
              <li className={styles.agreement__item}>
                Исполнитель не может гарантировать ни Заказчику, ни третьим лицам достижение Заказчиком в связи с получением услуг по настоящему Договору каких-либо ожидаемых Заказчиком и (или) третьими лицами результатов.
              </li>
              <li className={styles.agreement__item}>
                Временная приостановка доступа к Модулю любого типа и продолжительности невозможна.
              </li>
            </ol>
          </li>
          <li className={styles.agreement__item}>
            <h3 className={styles.agreement__header}>
              Права и обязанности сторон
            </h3>
            <ol className={styles.agreement__items}>
              <li className={styles.agreement__item}>
                <h3 className={styles.agreement__header}>
                  Исполнитель имеет право
                </h3>
                <ol className={styles.agreement__items}>
                  <li className={styles.agreement__item}>
                    Вносить изменения в содержание Модуля (в целом либо в части отдельных   Блоков) без согласования с Заказчиком.
                  </li>
                  <li className={styles.agreement__item}>
                    Привлекать третьих лиц для исполнения настоящего Договора без согласования с Заказчиком.
                  </li>
                  <li className={styles.agreement__item}>
                    Самостоятельно определять формы, методы и способы оказания услуг по настоящему Договору.
                  </li>
                  <li className={styles.agreement__item}>
                    Предоставить Заказчику доступ к выбранному Модулю после выполнения условий предоставления доступа.
                  </li>
                  <li className={styles.agreement__item}>
                    Временно приостанавливать предоставление доступа к Модулю для проведения необходимых плановых профилактических и ремонтных работ на Сайте.
                  </li>
                  <li className={styles.agreement__item}>
                    Требовать от Заказчика своевременной оплаты стоимости Модуля на условиях, предусмотренных настоящим Договором.
                  </li>
                  <li className={styles.agreement__item}>
                    Если Заказчик не оплатил стоимость Модуля полностью либо частично в сроки, предусмотренные в настоящем Договоре, Исполнитель вправе не предоставлять Заказчику доступ к Модулю до даты фактического перечисления Заказчиком денежных средств на расчетный счет Исполнителя.
                  </li>
                  <li className={styles.agreement__item}>
                    Исполнитель вправе в одностороннем порядке отказаться от предоставления Заказчику доступа к Модулю в следующих случаях:
                    <ul>
                      <li>
                        если просрочка оплаты Заказчиком выбранного Модуля (любого из очередных платежей) составит 5 (пять) и более календарных дней;
                      </li>
                      <li>
                        в случае нарушения Заказчиком правил поведения: общие призывы к недоверию Исполнителю очно и в сети Интернет, оскорбление и угрозы в адрес сотрудников Исполнителя, попытки хакерских атак, нарушение Заказчиком исключительных прав на объекты интеллектуальной собственности и/или имущественных прав Исполнителя, систематического создания конфликтных ситуаций, поведения Заказчика, мешающего оказанию услуг и т.п.
                      </li>
                    </ul>
                      Отказ от исполнения Договора Исполнителем производится путем направления Заказчику уведомления по адресу электронной почты, в мессенджер или по адресу места жительства. Любой из указанных способов считается уведомлением в письменной форме. В случае отказа Исполнителя от исполнения Договора по указанным в настоящем пункте Договора основаниям считается, что услуги не были оказаны Заказчику Исполнителем по вине Заказчика, и денежные средства за оплаченные услуги возврату не подлежат.
                  </li>
                  <li className={styles.agreement__item}>
                    Осуществлять фотосъёмку, видеозапись, аудиозапись мероприятий, связанных с оказанием услуг, встреч, разговоров (в том числе телефонных) с Заказчиком, его представителями. Заключением Договора Заказчик даёт своё согласие на осуществление Исполнителем вышеназванных действий и предоставляет Исполнителю право обнародовать, распространять, копировать, редактировать и иным образом использовать фотографии, аудио - и видеозаписи со своим изображением и (или) голосом, а также дает свое согласие на использование фотосъёмок, видеозаписей, аудиозаписей со своим изображением и (или) голосом как доказательств, в том числе при возникновении споров.
                  </li>
                  <li className={styles.agreement__item}>
                    Требовать от Заказчика исполнения обязанностей, установленных настоящим Договором.
                  </li>
                  <li className={styles.agreement__item}>
                    Реализовывать иные права, предусмотренные настоящим Договором и действующим законодательством Республики Беларусь.
                  </li>
                </ol>
              </li>
              <li className={styles.agreement__item}>
                <h3 className={styles.agreement__header}>
                  Исполнитель обязан
                </h3>
                <ol className={styles.agreement__items}>
                  <li className={styles.agreement__item}>
                    Предоставить Заказчику доступ к выбранному Модулю после выполнения им условий предоставления доступа.
                  </li>
                  <li className={styles.agreement__item}>
                    В случае обнаружения ошибок/недоработок в функционировании Сайта, допущенных Исполнителем либо по вине Исполнителя, своими силами и за свой счет устранить обнаруженные ошибки/недоработки.
                  </li>
                  <li className={styles.agreement__item}>
                    Осуществлять информационную поддержку Заказчика по вопросам оказания услуг и/или функционирования Сайта по рабочим дням с 10:00 до 19:00 (до 18:00 в пятницу и рабочие дни, являющиеся сокращенными в соответствии с законодательством Республики Беларусь).
                  </li>
                  <li className={styles.agreement__item}>
                    Вопросы по поддержке направляются Заказчиком по электронному адресу: platform@overone.by.
                  </li>
                </ol>
              </li>
              <li className={styles.agreement__item}>
                <h3 className={styles.agreement__header}>
                  Заказчик имеет право
                </h3>
                <ol className={styles.agreement__items}>
                  <li className={styles.agreement__item}>
                    На получение доступа к Модулю после выполнения условий предоставления доступа.
                  </li>
                  <li className={styles.agreement__item}>
                    Получать информационную поддержку по вопросам, связанным с порядком оказания услуги и функционированием Сайта.
                  </li>
                  <li className={styles.agreement__item}>
                    Реализовывать иные права, предусмотренные Договором и действующим законодательством Республики Беларусь.
                  </li>
                </ol>
              </li>
              <li className={styles.agreement__item}>
                <h3 className={styles.agreement__header}>
                  Заказчик обязан
                </h3>
                <ol className={styles.agreement__items}>
                  <li className={styles.agreement__item}>
                    Осуществить доступ к Модулю в течение 24 часов с момента предоставления Исполнителем доступа. 
                  </li>
                  <li className={styles.agreement__item}>
                    Использовать функционал   Сайта   исключительно   в   личных, семейных или иных  некоммерческих целях.
                  </li>
                  <li className={styles.agreement__item}>
                    Сообщить Исполнителю полные, достоверные, точные и актуальные сведения (включая контактные данные), которые необходимы Исполнителю при заключении, исполнении Договора. Если Заказчик не выполнил данное требование, и Исполнитель направил Заказчику информацию или материалы в соответствии с представленными ему сведениями, считается, что Исполнитель выполнил свои обязательства по Договору надлежащим образом. Если из-за указания неполных, недостоверных, неточных или неактуальных контактных данных Заказчика, Заказчик не смог своевременно получить от Исполнителя доступ к Модулю и воспользоваться услугами, считается, что невозможность исполнения Договора возникла по вине Заказчика, при этом услуги подлежат оплате в полном объеме, их стоимость Заказчику не возвращается.
                  </li>
                  <li className={styles.agreement__item}>
                    Незамедлительно в письменной форме уведомить Исполнителя об изменениях своих контактных данных, в течение 1(одного) календарного дня с момента таких изменений посредством направления информации по Адресу  электронной почты Исполнителя. Исполнитель не несёт ответственности за неполучение от Заказчика указанной информации.
                  </li>
                  <li className={styles.agreement__item}>
                    Своевременно и в полном объеме оплачивать стоимость услуги, согласно условиям настоящего Договора.
                  </li>
                  <li className={styles.agreement__item}>
                  Не размещать на сторонних ресурсах персональные данные других лиц, а равно не использовать и не пытаться получить персональные данные других пользователей.
                  </li>
                  <li className={styles.agreement__item}>
                    Не загружать, не хранить, не публиковать, не распространять и не предоставлять доступ или иным образом использовать вирусы, троянские и другие вредоносные программы.
                  </li>
                  <li className={styles.agreement__item}>
                    Нести иные обязанности, предусмотренные действующим законодательством Республики Беларусь и настоящим Договором.
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li className={styles.agreement__item}>
            <h3 className={styles.agreement__header}>
              Стоимость услуг и порядок оплаты
            </h3>
            <ol className={styles.agreement__items}>
              <li className={styles.agreement__item}>
                Стоимость услуг Исполнителя определяется на основании стоимости доступа к соответствующему Модулю, утвержденному Прейскурантом, размещенном на Сайте, и указывается в выставляемом Заказчику Счете. Исполнитель освобожден от уплаты НДС в соответствии с п. 27 главы 5 «Положения о Парке высоких технологий», утвержденного Декретом Президента Республики Беларусь № 12 от 22.09.2005 г. «О Парке высоких технологий». 
              </li>
              <li className={styles.agreement__item}>
                Заказчик производит оплату стоимости услуг Исполнителя (стоимости доступа к Модулю) путем 100% предоплаты единовременно путем перечисления денежных средств на расчетный счет Исполнителя не позднее чем за  1 (один) день до начала предоставления доступа, если иные условия не указаны в выставляемом Заказчику Счете.
              </li>
              <li className={styles.agreement__item}>
                Исполнитель вправе предоставить Заказчику Скидку на выбранную Заказчиком услугу. Размер предоставленной Скидки указывается в выставляемом Заказчику Счете.
              </li>
              <li className={styles.agreement__item}>
                Оплата стоимости услуг Исполнителя (стоимости доступа к Модулю) производится следующим образом:
              </li>
              <li className={styles.agreement__item}>
                <ol className={styles.agreement__items}>
                  <li className={styles.agreement__item}>
                    В безналичном порядке:
                    <ul>
                      <li>
                        путем перечисления средств на расчетный счет Исполнителя (в том числе посредством эквайринга (интернет, торговый), указанный в Договоре;
                      </li>
                      <li>
                        через систему bePaid (Заказчик оплачивает услугу путем введения реквизитов своей банковской карты на специальной защищённой платежной странице процессинговой системы bePaid (ссылка предоставляется со стороны Исполнителя). В дальнейшем денежные средства за оказываемые услуги по доступу к Модулю  будут списываться с карты Заказчика ежемесячно автоматически в соответствии с графиком платежей, который составляется индивидуально. Автоматическое списание стоимости услуги по доступу к Модулю  происходит с даты оформления доступа  до даты его окончания или до момента отмены доступа. Оплачивая услуги по предоставлению доступа к Модулю, Заказчик дает свое согласие на привязку карты (реквизиты карты не хранятся у Исполнителя) для последующих оплат по сохраненной карте. Отмена доступа к Модулю производится путем направления Исполнителю Заказчиком уведомления по адресу электронной почты, в мессенджер или на юридический адрес. 
                      </li>
                      <li>
                        через ЕРИП;
                      </li>
                    </ul>
                  </li>
                  <li className={styles.agreement__item}>
                    Путем внесения наличных средств на расчетный счет Исполнителя через кассы банков;
                  </li>
                  <li className={styles.agreement__item}>
                    За счет кредита, полученного Заказчиком от Банка для оплаты стоимости услуг, путем перечисления Банком суммы стоимости услуг на расчетный счет Исполнителя. Обязательства Банка по предоставлению кредита и перечислению денежных средств Исполнителю подтверждаются кредитным договором, заключаемым Заказчиком с Банком.
                  </li>
                  <li className={styles.agreement__item}>
                    Датой оплаты услуг  по доступу к Модулю является дата поступления денежных средств на расчетный счет Исполнителя.
                  </li>
                </ol>
              </li>
              <li className={styles.agreement__item}>
                Стоимость не оплаченных услуг Исполнителя (стоимость доступа к Модулю) может быть изменена Исполнителем в одностороннем порядке.
              </li>
              <li className={styles.agreement__item}>
                Об изменении стоимости услуг Исполнителя (стоимости доступа к Модулю) Заказчик должен быть уведомлён в разумный срок по выбору Исполнителя одним из следующих способов:
                <ul>
                  <li>
                    отправка уведомления в письменной форме (заказным письмом). В указанном случае Заказчик считается уведомлённым в момент вручения ему уведомления;
                  </li>
                  <li>
                    отправка уведомления в электронной форме по адресу электронной почты Заказчика. В указанном случае Заказчик считается уведомлённым в момент получения уведомления в электронной форме.
                  </li>
                </ul>
              </li>
              <li className={styles.agreement__item}>
                Стоимость уже оплаченных Заказчиком услуг (стоимости доступа к Модулю) Исполнителя не может быть изменена Исполнителем в одностороннем порядке.
              </li>
              <li className={styles.agreement__item}>
                Заказчик самостоятельно совершает все действия, необходимые для истребования им денежных средств от Банка в случае, если на момент возврата денежных средств Исполнителем Банку имела место разница между суммой задолженности Заказчика перед Банком и суммой, поступившей от Исполнителя Банку.
              </li>
            </ol>
          </li>
          <li className={styles.agreement__item}>
            <h3 className={styles.agreement__header}>
              Интеллектуальная собственность и авторское право
            </h3>
            <ol className={styles.agreement__items}>
              <li className={styles.agreement__item}>
                Любая информация, предоставляемая Исполнителем в ходе оказания услуг и входящая в содержание материалов Модуля, в том числе текстовая информация, графические изображения, аудио- и видеоматериалы, а также аудио и видеозаписи занятий, Сайт и содержащиеся на нем материалы являются объектами интеллектуальной собственности Исполнителя.
              </li>
              <li className={styles.agreement__item}>
                Заказчик обязуется не копировать материалы Модуля на аудио- и/или видеоносители, не воспроизводить, не повторять, не продавать, а также не использовать любым иным образом в каких бы то ни было целях информацию и любые материалы, ставшие ему доступными в связи с оказанием услуг, за исключением их личного использования. Если Заказчик допустит неправомерное использование, распространение указанной информации, он обязан во внесудебном порядке по письменному требованию Исполнителя оплатить штраф в размере 15 000,00 рублей (пятнадцать тысяч белорусских рублей), а также возместить Исполнителю причиненные таким нарушением убытки.
              </li>
              <li className={styles.agreement__item}>
                Нарушение прав интеллектуальной собственности Исполнителя влечет ответственность согласно действующему законодательству Республики Беларусь.
              </li>
            </ol>
          </li>
          <li className={styles.agreement__item}>
            <h3 className={styles.agreement__header}>
              Ответственность сторон
            </h3>
            <ol className={styles.agreement__items}>
              <li className={styles.agreement__item}>
                За неисполнение или ненадлежащее исполнение своих обязательств по настоящему Договору стороны несут ответственность в соответствии с законодательством Республики Беларусь.
              </li>
              <li className={styles.agreement__item}>
                В случае возникновения исходящего от Заказчика в адрес Исполнителя требования по возврату денежных средств за оказанные услуги по доступу к Модулю, Заказчик оплачивает Исполнителю штраф в размере 50% от общей стоимости услуги, путем удержания Исполнителем суммы штрафа из истребуемой суммы возврата.
              </li>
              <li className={styles.agreement__item}>
                В случае нарушения Заказчиком условий настоящего Договора Исполнитель вправе деактивировать доступ  к Модулю без возврата уплаченных денежных средств.
              </li>
              <li className={styles.agreement__item}>
                В случае нарушения сроков оплаты стоимости услуг Исполнителя (стоимости доступа к Модулю), предусмотренных Договором, Заказчик уплачивает Исполнителю пеню в размере 0,15% от стоимости услуги за каждый день просрочки оплаты.
              </li>
              <li className={styles.agreement__item}>
                В случае одностороннего отказа Заказчика от Договора, денежные средства возвращаются Заказчику следующим образом:
                <ul>
                  <li>
                    если Заказчик отказывается от Договора в течение 15 календарных дней с даты предоставления Исполнителем доступа к выбранному Заказчиком Модулю  - Заказчику возвращается 50%  от оплаченной суммы; 
                  </li>
                  <li>
                    случае если Заказчик отказывается от Договора по истечении 30 календарных дней с даты предоставления Исполнителем доступа к выбранному Заказчиком Модулю, возврат денежных средств не осуществляется.
                  </li>
                </ul>
              </li>
              <li className={styles.agreement__item}>
                В целях однозначного толкования: при отказе Заказчика от услуг, оплаченных с использованием кредитных средств (банковских продуктов – потребительских кредитов, рассрочек и пр.), сумма к возврату и расходы Исполнителя рассчитываются от суммы денег, полученных Исполнителем от банка в счет оплаты услуг, оказываемых Заказчику.
              </li>
              <li className={styles.agreement__item}>
                Исполнитель не несет ответственности:
                <ul>
                  <li>
                    за содержание информации, размещаемой в чатах мессенджеров, которые создаются по инициативе пользователей, включая Заказчика;
                  </li>
                  <li>
                    за использование третьими лицами персональных данных, которые Заказчик сообщает в таких чатах;
                  </li>
                  <li>
                    за качество соединения с сетью Интернет и функционирование оборудования и программного обеспечения Заказчика;
                  </li>
                  <li>
                    за несоответствие минимально рекомендуемых технических характеристик оборудования Заказчика требованиям, предъявляемым для воспроизведения материалов Модуля;
                  </li>
                  <li>
                    за использование (невозможность использования) и какие бы то ни было последствия использования (невозможности использования) Заказчиком выбранной им формы оплаты услуг по Договору;
                  </li>
                  <li>
                    за действия (бездействие) банков или третьих лиц, участвующих в расчетах при оплате услуги или возврате денежных средств в установленных случаях.
                  </li>
                </ul>
              </li>
              <li className={styles.agreement__item}>
                Исполнитель прилагает все разумные усилия, предотвращающие сбои и неполадки в работе Сайта, однако не гарантирует его бесперебойную работу, не несет ответственности и не возмещает убытки, возникшие или возникновение которых возможно в связи с техническими сбоями и перебоями в работе провайдера, иных лиц или служб, ограничением доступа к сети Интернет или Интернет-ресурсу по любым причинам, необеспечением Заказчиком сохранности данных доступа к своему личному кабинету и другими обстоятельствами, находящимися вне компетенции Исполнителя.
              </li>
              <li className={styles.agreement__item}>
                Заказчик отвечает за соблюдение конфиденциальности своих данных (логина, пароля, секретного ключа), используемых для авторизации на Сайте. Все действия, совершенные на Сайте с использованием логина и (или) пароля Заказчика, считаются действиями самого Заказчика.
              </li>
              <li className={styles.agreement__item}>
                Заказчик не имеет права предоставлять доступ третьим лицам, продавать свой аккаунт, обменивать его или применять любые коммерческие действия. В случае выявления таких действий со стороны Заказчика ему будет заблокирован доступ к Сайту с последующим отключением доступа к Модулю, а все обязательства со стороны Исполнителя по данному Договору автоматически прекращаются без возврата денежных средств.
              </li>
              <li className={styles.agreement__item}>
                Если Заказчик не исполняет (не надлежаще исполняет) свои     обязательства по Договору, Исполнитель вправе прекратить действие настоящего Договора путем одностороннего внесудебного отказа от его исполнения посредством направления уведомления о таком отказе в электронном виде на адрес электронной почты, на который ранее Исполнителем были высланы данные для предоставления доступа. Действие настоящего Договора прекращается в день получения Заказчиком соответствующего уведомления или в срок, указанный в уведомлении. В случае прекращения настоящего Договора по причине невнесения оплаты согласно графику платежей, указанному в Счете, Исполнитель не предоставляет доступ к Блоку (составной части Модуля), оплата за доступ к которому не была внесена.
              </li>
            </ol>
          </li>
          <li className={styles.agreement__item}>
            <h3 className={styles.agreement__header}>
              Форс-мажор
            </h3>
            <ol className={styles.agreement__items}>
              <li className={styles.agreement__item}>
                Стороны освобождаются от ответственности за неисполнение или ненадлежащее исполнение взятых на себя обязательств, если причиной тому стало возникновение обстоятельств непреодолимой силы (форс-мажор). К форс-мажорным обстоятельствам относятся стихийные бедствия, аварии, пожары, массовые беспорядки, забастовки, военные действия, действия третьих лиц (в том числе расторжение договора аренды, в котором Исполнитель выступает арендатором, ограничение деятельности Исполнителя арендодателем, государственными органами и другими лицами), вступление в силу законодательных актов, постановлений и распоряжений государственных органов и организаций, прямо или косвенно запрещающих указанные в Договоре виды деятельности, иные обстоятельства, не зависящие от воли Исполнителя или Заказчика.
              </li>
              <li className={styles.agreement__item}>
                Сторона, которая не исполнила обязательства по Договору в силу форс-мажорных обстоятельств, должна незамедлительно известить об этом другую сторону. Такая сторона обязана доказать наличие форс-мажорных обстоятельств и их влияние на невозможность должного исполнения обязательств по Договору.
              </li>
            </ol>
          </li>
          <li className={styles.agreement__item}>
            <h3 className={styles.agreement__header}>
              Порядок разрешения споров
            </h3>
            <ol className={styles.agreement__items}>
              <li className={styles.agreement__item}>
                Все споры и разногласия, возникшие при исполнении Договора, подлежат урегулированию в досудебном порядке путем проведения переговоров.
              </li>
              <li className={styles.agreement__item}>
                Сторона, которая полагает, что ее права по Договору нарушены, обязана предъявить претензию второй стороне. Претензия считается предъявленной надлежащим образом, если направлена другой стороне любым способом, позволяющим установить личность отправителя и факт вручения корреспонденции получателю. Факт возврата корреспонденции с указанием на отсутствие получателя не препятствует обращению в суд. Приложение к претензии копий документов, обосновывающих и подтверждающих предъявленные требования, если данные документы имеются в наличии у второй стороны, не является обязательным.
              </li>
              <li className={styles.agreement__item}>
                Сторона, получившая претензию, в течение 15 (пятнадцати) рабочих дней со дня ее получения письменно уведомляет вторую сторону о результатах рассмотрения. Неполучение ответа либо мотивированных возражений на претензию в установленный срок рассматривается как согласие с претензией и изложенными в ней обстоятельствами.
              </li>
              <li className={styles.agreement__item}>
                Если сторонам не удастся достичь согласия путем переговоров, то любой спор, разногласие или требования, возникшие из Договора или касающиеся его нарушения, подлежат разрешению в уполномоченных судах Республики Беларусь по месту нахождения Исполнителя.
              </li>
            </ol>
          </li>
          <li className={styles.agreement__item}>
            <h3 className={styles.agreement__header}>
              Прочие условия
            </h3>
            <ol className={styles.agreement__items}>
              <li className={styles.agreement__item}>
                Настоящий договор считается заключенным с момента выполнения Заказчиком действий, предусмотренных п.2.5.Договора и действует до исполнения сторонами своих обязательств.
              </li>
              <li className={styles.agreement__item}>
                За исключением случаев, прямо предусмотренных Договором, Исполнитель имеет право в любое время в одностороннем порядке без предварительного уведомления и согласия Заказчика вносить изменения в Договор.
              </li>
              <li className={styles.agreement__item}>
                Текст изменений в Договор доводится Исполнителем до всеобщего сведения посредством размещения соответствующей информации на Сайте. Заказчик, зная о возможности таких изменений, согласен с тем, что они будут производиться в указанном порядке, и обязан самостоятельно отслеживать изменения, вносимые Договор.
              </li>
              <li className={styles.agreement__item}>
                Расторжение Договора производится по соглашению Сторон, в одностороннем порядке Стороной в случаях, предусмотренных Договором и в иных случаях, предусмотренных законодательством.
              </li>
              <li className={styles.agreement__item}>
                Стороны признают юридическую силу документов и информации, переданных посредством электронной почты или иных электронных каналов, равную юридическую силу собственноручной подписи и факсимильной подписи (воспроизведенное механическим способом с использованием клише), иных способов создания, подписания и заключения документов, имеющих значение для заключения, исполнения или прекращения Договора.
              </li>
              <li className={styles.agreement__item}>
                Для коммуникации и передачи документов и информации, имеющих юридическую силу, Стороны согласились использовать следующие адреса электронной почты: Заказчик – адрес электронной почты, указанный Заказчиком, Исполнитель – адрес, указанный в реквизитах к Договору. Использование иных адресов электронной почты не допускается.
              </li>
              <li className={styles.agreement__item}>
                Если Договором предусмотрено уведомление путем осуществления телефонного звонка, отправки электронного письма по адресу электронной почты, отправки сообщения в мессенджер, размещения информации на Сайте, Заказчик считается уведомленным надлежащим образом в момент осуществления телефонного звонка, доставки электронного письма по адресу электронной почты, доставки сообщения в мессенджер, размещения информации на Сайте вне зависимости от прочтения письма, сообщения. Заказчик обязуется осуществлять постоянный контроль телефонных звонков, электронных писем на электронной почте, сообщений в мессенджерах, информации на Сайте.
              </li>
              <li className={styles.agreement__item}>
                В случае если какое-либо из условий настоящего Договора утратит юридическую силу, будет признано незаконным или будет исключено из настоящего Договора, это не влечет недействительность остальных условий настоящего Договора, которые сохранят юридическую силу и являются обязательными для Сторон.
              </li>
              <li className={styles.agreement__item}>
                Все вопросы, не урегулированные настоящим Договором, разрешаются в соответствии с действующим законодательством Республики Беларусь.
              </li>
              <li className={styles.agreement__item}>
                Заключением Договора Заказчик подтверждает, что до момента его заключения Заказчик ознакомился с его условиями, с Прейскурантом, всеми иными материалами, данными и информацией, размещенными на Сайте, получил полную и достоверную информацию о содержании услуг, их особенностях, стоимости услуг и порядке их оплаты, наименовании, местонахождении Исполнителя, ознакомился с документами, дающими Исполнителю право на осуществление деятельности, ознакомился с условиями Договора, получил от Исполнителя информацию по всем интересующим Заказчика вопросам, связанным с Договором, условия Договора ему ясны и он с ними согласен.
              </li>
              <li className={styles.agreement__item}>
                При надлежащем соблюдении условий настоящего Договора и после полного получения Услуги в срок согласно п. 3.3. настоящего Договора, при наличии технической возможности, определяемой Исполнителем, Заказчику предоставляется право доступа к дополнительным материалам Модуля на протяжении 1 (одного) месяца. В этом случае в стоимость оказанной Услуги включаются дополнительные материалы Модуля.
              </li>
              <li className={styles.agreement__item}>
                Заказчик подтверждает, что он является дееспособным и достиг возраста, необходимого в соответствии с законодательством Республики Беларусь для совершения сделок с использованием Сайта.
              </li>
              <li className={styles.agreement__item}>
                Стороны безоговорочно соглашаются с тем, что молчание (отсутствие письменных уведомлений о несогласии с отдельными положениями настоящего Договора, в том числе с изменением тарифов) признается согласием и присоединением Заказчика к новой редакции настоящего Договора (п.3 ст.159 Гражданского Кодекса Республики Беларусь).
              </li>
            </ol>
          </li>
          <li className={styles.agreement__item}>
            <h3 className={styles.agreement__header}>
              Реквизиты сторон
            </h3>
            <ol className={styles.agreement__items}>
              <li className={styles.agreement__item}>
                Стороны соглашаются реквизитами Заказчика считать информацию, указанную им при регистрации на Сайте и/или оформлении заявки на предоставление доступа Модулю, и/или оплате услуг Исполнителя.
              </li>
            </ol>
          </li>
        </ol>
        <section className={styles.agreement__properties}>
          <p className={styles.agreement__property}>
            Реквизиты Заказчика
            <br/>
            ООО «Оверван»
            <br/>
            г.Минск, ул.Сурганова 43-508
            <br/>
            р/с BY55 ALFA 3012 2639 1200 0000
            <br/>
            в ЗАО «Альфа-Банк», БИК ALFABY2X
            <br/>
            УПН 193417722
            <br/>
            e-mail: it@overone.by
          </p>
          <p className={styles.agreement__property}>
            Реквизиты Исполнителя
          </p>
        </section>
    </section>
    )
}