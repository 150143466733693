export const checkedIcon =
  'M7.74805 10.9084V10.8409C7.75278 10.4006 7.79658 10.0502 7.87944 9.78977C7.96467 9.52936 8.0854 9.31866 8.24165 9.15767C8.3979 8.99669 8.58612 8.84991 8.80629 8.71733C8.94833 8.62737 9.07617 8.52675 9.18981 8.41548C9.30344 8.30421 9.39341 8.17637 9.45969 8.03196C9.52598 7.88755 9.55913 7.72775 9.55913 7.55256C9.55913 7.34186 9.50941 7.15956 9.40998 7.00568C9.31055 6.8518 9.17797 6.73343 9.01225 6.65057C8.8489 6.56534 8.66661 6.52273 8.46538 6.52273C8.28308 6.52273 8.10908 6.56061 7.94336 6.63636C7.77764 6.71212 7.64033 6.83049 7.53143 6.99148C7.42253 7.15009 7.35979 7.35488 7.34322 7.60582H6.26367C6.28024 7.17969 6.38796 6.81984 6.58683 6.52628C6.78569 6.23035 7.04847 6.00663 7.37518 5.85511C7.70425 5.7036 8.06765 5.62784 8.46538 5.62784C8.90098 5.62784 9.28214 5.70952 9.60884 5.87287C9.93555 6.03385 10.1889 6.25994 10.3688 6.55114C10.5511 6.83996 10.6422 7.17732 10.6422 7.56321C10.6422 7.82836 10.6008 8.06747 10.5179 8.28054C10.4351 8.49124 10.3167 8.67945 10.1628 8.84517C10.0113 9.01089 9.82901 9.15767 9.61594 9.28551C9.41471 9.41098 9.25136 9.54119 9.12589 9.67614C9.00278 9.81108 8.91282 9.97088 8.856 10.1555C8.79918 10.3402 8.76841 10.5687 8.76367 10.8409V10.9084H7.74805ZM8.28427 13.0675C8.09014 13.0675 7.92324 12.9988 7.78356 12.8615C7.64388 12.7218 7.57404 12.5537 7.57404 12.3572C7.57404 12.1631 7.64388 11.9974 7.78356 11.8601C7.92324 11.7204 8.09014 11.6506 8.28427 11.6506C8.47603 11.6506 8.64175 11.7204 8.78143 11.8601C8.92347 11.9974 8.9945 12.1631 8.9945 12.3572C8.9945 12.4875 8.96135 12.607 8.89506 12.7159C8.83114 12.8224 8.74592 12.9077 8.63938 12.9716C8.53285 13.0355 8.41448 13.0675 8.28427 13.0675Z'

export const noCheckedIcon =
  'M7.74805 10.9084V10.8409C7.75278 10.4006 7.79658 10.0502 7.87944 9.78977C7.96467 9.52936 8.0854 9.31866 8.24165 9.15767C8.3979 8.99669 8.58612 8.84991 8.80629 8.71733C8.94833 8.62737 9.07617 8.52675 9.18981 8.41548C9.30344 8.30421 9.39341 8.17637 9.45969 8.03196C9.52598 7.88755 9.55913 7.72775 9.55913 7.55256C9.55913 7.34186 9.50941 7.15956 9.40998 7.00568C9.31055 6.8518 9.17797 6.73343 9.01225 6.65057C8.8489 6.56534 8.66661 6.52273 8.46538 6.52273C8.28308 6.52273 8.10908 6.56061 7.94336 6.63636C7.77764 6.71212 7.64033 6.83049 7.53143 6.99148C7.42253 7.15009 7.35979 7.35488 7.34322 7.60582H6.26367C6.28024 7.17969 6.38796 6.81984 6.58683 6.52628C6.78569 6.23035 7.04847 6.00663 7.37518 5.85511C7.70425 5.7036 8.06765 5.62784 8.46538 5.62784C8.90098 5.62784 9.28214 5.70952 9.60884 5.87287C9.93555 6.03385 10.1889 6.25994 10.3688 6.55114C10.5511 6.83996 10.6422 7.17732 10.6422 7.56321C10.6422 7.82836 10.6008 8.06747 10.5179 8.28054C10.4351 8.49124 10.3167 8.67945 10.1628 8.84517C10.0113 9.01089 9.82901 9.15767 9.61594 9.28551C9.41471 9.41098 9.25136 9.54119 9.12589 9.67614C9.00278 9.81108 8.91282 9.97088 8.856 10.1555C8.79918 10.3402 8.76841 10.5687 8.76367 10.8409V10.9084H7.74805ZM8.28427 13.0675C8.09014 13.0675 7.92324 12.9988 7.78356 12.8615C7.64388 12.7218 7.57404 12.5537 7.57404 12.3572C7.57404 12.1631 7.64388 11.9974 7.78356 11.8601C7.92324 11.7204 8.09014 11.6506 8.28427 11.6506C8.47603 11.6506 8.64175 11.7204 8.78143 11.8601C8.92347 11.9974 8.9945 12.1631 8.9945 12.3572C8.9945 12.4875 8.96135 12.607 8.89506 12.7159C8.83114 12.8224 8.74592 12.9077 8.63938 12.9716C8.53285 13.0355 8.41448 13.0675 8.28427 13.0675Z'

export const dotBlockIcon = {
  d1: 'M2.5 4C3.60457 4 4.5 3.10457 4.5 2C4.5 0.895431 3.60457 0 2.5 0C1.39543 0 0.5 0.895431 0.5 2C0.5 3.10457 1.39543 4 2.5 4Z',
  d2: 'M2.5 11C3.60457 11 4.5 10.1046 4.5 9C4.5 7.89543 3.60457 7 2.5 7C1.39543 7 0.5 7.89543 0.5 9C0.5 10.1046 1.39543 11 2.5 11Z',
  d3: 'M4.5 16C4.5 17.1046 3.60457 18 2.5 18C1.39543 18 0.5 17.1046 0.5 16C0.5 14.8954 1.39543 14 2.5 14C3.60457 14 4.5 14.8954 4.5 16Z',
  d4: 'M9.5 4C10.6046 4 11.5 3.10457 11.5 2C11.5 0.895431 10.6046 0 9.5 0C8.39543 0 7.5 0.895431 7.5 2C7.5 3.10457 8.39543 4 9.5 4Z',
  d5: 'M11.5 9C11.5 10.1046 10.6046 11 9.5 11C8.39543 11 7.5 10.1046 7.5 9C7.5 7.89543 8.39543 7 9.5 7C10.6046 7 11.5 7.89543 11.5 9Z',
  d6: 'M9.5 18C10.6046 18 11.5 17.1046 11.5 16C11.5 14.8954 10.6046 14 9.5 14C8.39543 14 7.5 14.8954 7.5 16C7.5 17.1046 8.39543 18 9.5 18Z',
}
