// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".navAccount_nav_account__yG7NK {\n  margin: 10px 0 22px 13px;\n  min-height: 48px;\n}\n@media only screen and (min-width: 320px) and (max-width: 375px) {\n  .navAccount_nav_account__yG7NK {\n    display: grid;\n    grid-template-columns: repeat(2, 1fr);\n    grid-row-gap: 1em;\n  }\n}\n@media only screen and (min-width: 375px) and (max-width: 480px) {\n  .navAccount_nav_account__yG7NK {\n    display: grid;\n    grid-template-columns: repeat(2, 1fr);\n    grid-row-gap: 1em;\n  }\n}\n\n.navAccount_nav_account__yG7NK a:nth-last-child(n+2) {\n  margin-right: 10px;\n}", "",{"version":3,"sources":["webpack://./src/Pages/School/NavAccount/navAccount.module.scss"],"names":[],"mappings":"AAAA;EACE,wBAAA;EACA,gBAAA;AACF;AACE;EAJF;IAKI,aAAA;IACA,qCAAA;IACA,iBAAA;EAEF;AACF;AAAE;EAVF;IAWI,aAAA;IACA,qCAAA;IACA,iBAAA;EAGF;AACF;;AACA;EACE,kBAAA;AAEF","sourcesContent":[".nav_account {\n  margin: 10px 0 22px 13px;\n  min-height: 48px;\n\n  @media only screen and (min-width: 320px) and (max-width: 375px) {\n    display: grid;\n    grid-template-columns: repeat(2, 1fr);\n    grid-row-gap: 1em;\n\n  }\n  @media only screen and (min-width: 375px) and (max-width: 480px) {\n    display: grid;\n    grid-template-columns: repeat(2, 1fr);\n    grid-row-gap: 1em;\n  }\n\n}\n\n.nav_account a:nth-last-child(n + 2) {\n  margin-right: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nav_account": "navAccount_nav_account__yG7NK"
};
export default ___CSS_LOADER_EXPORT___;
