import { editorSvgLabel } from '../../../constants/iconSvgConstants'

export const labelHeaderOnePath = [{ d: editorSvgLabel.labelHeaderOne, fill: '#03053D' }]

export const labelHeaderTwoPath = [{ d: editorSvgLabel.labelHeaderTwo, fill: '#03053D' }]

export const labelHeaderThreePath = [{ d: editorSvgLabel.labelHeaderThree, fill: '#03053D' }]

export const labelBlockQuotePath = [{ d: editorSvgLabel.labelBlockquote, fill: '#03053D' }]

export const labelUnorderedListItemPath = [{ d: editorSvgLabel.labelUnorderedListItem, fill: '#03053D' }]

export const labelOrderedListItemPath = [{ d: editorSvgLabel.labelOrderedListItem, fill: '#03053D' }]

export const labelCodeBlockPath = [{ d: editorSvgLabel.labelCodeBlock, fill: '#03053D' }]

export const labelImagePath = [{ d: editorSvgLabel.labelImage, fill: '#03053D' }]

export const labelTextAlignCenterPath = [{ d: editorSvgLabel.textAlignCenter, fill: '#03053D' }]

export const labelTextAlignStartPath = [{ d: editorSvgLabel.textAlignStart, fill: '#03053D' }]

export const labelLineSpacesPath = [{ d: editorSvgLabel.lineSpaces, fill: '#03053D' }]

export const labelVideoTrackPath = [{ d: editorSvgLabel.videoTrack, fill: '#03053D' }]

export const labelTagIconPath = [{ d: editorSvgLabel.tagIcon, fill: '#03053D' }]

export const labelContentsIconPath = [{ d: editorSvgLabel.contentsIcon, fill: '#03053D' }]

export const labelBackArrPath = [{ d: editorSvgLabel.backArrIcon, fill: '#03053D' }]

export const labelForwardArrPath = [{ d: editorSvgLabel.forwardArrIcon, fill: '#03053D' }]

export const boldIconPath = [{ d: editorSvgLabel.labelBold, fill: '#03053D' }]

export const italicIconPath = [{ d: editorSvgLabel.labelItalic, fill: '#03053D' }]

export const underlineIconPath = [{ d: editorSvgLabel.labelUnderline, fill: '#03053D' }]

export const monoIconPath = [{ d: editorSvgLabel.labelMonochrome, fill: '#03053D' }]
