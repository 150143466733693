// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".input_label__HXSsl input {\n  padding: 0.3em;\n  width: 100%;\n  height: 100%;\n  border: 0.1rem solid #E5E7EB;\n  border-radius: 5px;\n  outline: none;\n}\n.input_label__HXSsl input:focus {\n  border: 1px solid #ebb0ff;\n}", "",{"version":3,"sources":["webpack://./src/components/common/Input/InputBlock/input.module.scss"],"names":[],"mappings":"AACI;EACI,cAAA;EACA,WAAA;EACA,YAAA;EACA,4BAAA;EACA,kBAAA;EACA,aAAA;AAAR;AAEQ;EACI,yBAAA;AAAZ","sourcesContent":[".label {\n    input {\n        padding: 0.3em;\n        width: 100%;\n        height: 100%;\n        border: 0.1rem solid #E5E7EB;\n        border-radius: 5px;\n        outline: none;\n\n        &:focus {\n            border: 1px solid #ebb0ff;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": "input_label__HXSsl"
};
export default ___CSS_LOADER_EXPORT___;
