// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SimpleLoader_loader__Yq7x8 {\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n  margin: 0 auto;\n  justify-content: center;\n  align-items: center;\n  max-width: 233px;\n  width: 100%;\n}\n.SimpleLoader_loader__Yq7x8 p {\n  font-family: \"SFPRORegular\";\n  font-size: 27.84px;\n  font-weight: 500;\n  line-height: 27.84px;\n  text-align: center;\n}\n\n.SimpleLoader_slider_container__TL3-H {\n  position: relative;\n  width: 100%;\n  max-width: 233px;\n  height: 200px;\n  overflow: hidden;\n  margin: auto;\n}\n\n.SimpleLoader_slide__oxi0p {\n  position: absolute;\n  height: 97%;\n  width: 100%;\n  object-fit: cover;\n  opacity: 0;\n}\n\n.SimpleLoader_active__DpiOk {\n  opacity: 1;\n}", "",{"version":3,"sources":["webpack://./src/components/Loaders/SimpleLoader/SimpleLoader.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,cAAA;EACA,uBAAA;EACA,mBAAA;EACA,gBAAA;EACA,WAAA;AACF;AACE;EACE,2BAAA;EACA,kBAAA;EACA,gBAAA;EACA,oBAAA;EACA,kBAAA;AACJ;;AAGA;EACE,kBAAA;EACA,WAAA;EACA,gBAAA;EACA,aAAA;EACA,gBAAA;EACA,YAAA;AAAF;;AAGA;EACE,kBAAA;EACA,WAAA;EACA,WAAA;EACA,iBAAA;EACA,UAAA;AAAF;;AAGA;EACE,UAAA;AAAF","sourcesContent":[".loader {\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n  margin: 0 auto;\n  justify-content: center;\n  align-items: center;\n  max-width: 233px;\n  width: 100%;\n\n  p {\n    font-family: 'SFPRORegular';\n    font-size: 27.84px;\n    font-weight: 500;\n    line-height: 27.84px;\n    text-align: center;\n  }\n}\n\n.slider_container {\n  position: relative;\n  width: 100%;\n  max-width: 233px;\n  height: 200px;\n  overflow: hidden;\n  margin: auto;\n}\n\n.slide {\n  position: absolute;\n  height: 97%;\n  width: 100%;\n  object-fit: cover;\n  opacity: 0;\n}\n\n.active {\n  opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": "SimpleLoader_loader__Yq7x8",
	"slider_container": "SimpleLoader_slider_container__TL3-H",
	"slide": "SimpleLoader_slide__oxi0p",
	"active": "SimpleLoader_active__DpiOk"
};
export default ___CSS_LOADER_EXPORT___;
