import { pathT } from 'types/commonComponentsTypes'

export const fileIconPath: pathT[] = [
  {
    d: 'M25.5 34.12L10.5 34.12C3.88 34.12 1.87 32.11 1.87 25.5L1.87 10.5C1.87 3.88 3.88 1.87 10.5 1.87L12.75 1.87C15.37 1.87 16.19 2.73 17.25 4.12L19.5 7.12C19.99 7.78 20.07 7.87 21 7.87L25.5 7.87C32.11 7.87 34.12 9.88 34.12 16.5L34.12 25.5C34.12 32.11 32.11 34.12 25.5 34.12ZM10.5 4.12C5.13 4.12 4.12 5.14 4.12 10.5L4.12 25.5C4.12 30.85 5.13 31.87 10.5 31.87L25.5 31.87C30.86 31.87 31.87 30.85 31.87 25.5L31.87 16.5C31.87 11.14 30.86 10.12 25.5 10.12L21 10.12C19.08 10.12 18.44 9.46 17.69 8.47L15.44 5.47C14.66 4.44 14.42 4.12 12.75 4.12L10.5 4.12Z',
    fill: '#332F36',
    fillRule: 'nonzero',
  },
  {
    d: 'M30 10.69C29.38 10.69 28.87 10.18 28.87 9.57L28.87 7.5C28.87 5.13 27.86 4.12 25.5 4.12L12 4.12C11.38 4.12 10.87 3.61 10.87 3C10.87 2.38 11.38 1.87 12 1.87L25.5 1.87C29.13 1.87 31.12 3.87 31.12 7.5L31.12 9.57C31.12 10.18 30.61 10.69 30 10.69Z',
    fill: '#332F36',
    fillRule: 'nonzero',
  },
]

export const downloadIconPath: pathT[] = [
  {
    d: 'M8 11.57C7.86 11.57 7.74 11.55 7.62 11.51C7.5 11.47 7.4 11.4 7.3 11.3L3.69 7.7C3.5 7.5 3.4 7.26 3.41 7C3.41 6.73 3.51 6.5 3.69 6.3C3.9 6.1 4.13 5.99 4.41 5.98C4.68 5.98 4.92 6.07 5.12 6.27L7 8.14L7 1C7 0.71 7.09 0.47 7.28 0.28C7.48 0.09 7.71 0 8 0C8.28 -0.01 8.51 0.09 8.71 0.28C8.9 0.48 9 0.71 9 1L9 8.14L10.87 6.27C11.07 6.07 11.31 5.97 11.58 5.98C11.86 5.99 12.1 6.09 12.3 6.3C12.48 6.5 12.58 6.73 12.58 7C12.59 7.26 12.5 7.5 12.3 7.7L8.69 11.3C8.59 11.4 8.49 11.47 8.37 11.51C8.25 11.55 8.13 11.57 8 11.57ZM2 16C1.44 16 0.97 15.8 0.58 15.41C0.19 15.02 0 14.55 0 14L0 12C0 11.71 0.09 11.47 0.28 11.28C0.48 11.09 0.71 11 1 11C1.28 10.99 1.51 11.09 1.71 11.28C1.9 11.48 2 11.71 2 12L2 14L14 14L14 12C14 11.71 14.09 11.47 14.28 11.28C14.48 11.09 14.71 11 15 11C15.28 10.99 15.51 11.09 15.71 11.28C15.9 11.48 16 11.71 16 12L16 14C16 14.55 15.8 15.02 15.41 15.41C15.02 15.8 14.55 16 14 16L2 16Z',
    fill: '#332F36',
    fillRule: 'evenodd',
  },
]
