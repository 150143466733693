import { FC } from 'react'
import { pathT } from 'types/commonComponentsTypes'
import { IconSvg } from '../common/IconSvg/IconSvg'

export const mutedIconPath: pathT[] = [
  {
    d: 'M22.07 23.01L18.06 19C17.69 19.24 17.31 19.45 16.91 19.63C16.51 19.8 16.09 19.96 15.67 20.1L15.67 18.7C15.93 18.61 16.18 18.51 16.42 18.41C16.66 18.3 16.89 18.18 17.1 18.04L11.98 12.92L11.98 17.54L7.21 12.77L2.64 12.77L2.64 7.44L6.5 7.44L0 0.94L0.94 0L23.01 22.07L22.07 23.01ZM21.03 16.33L20.1 15.4C20.65 14.64 21.07 13.81 21.37 12.91C21.67 12.01 21.82 11.06 21.82 10.08C21.82 8.12 21.26 6.36 20.13 4.78C19 3.2 17.51 2.09 15.67 1.45L15.67 0.05C17.89 0.76 19.7 2.03 21.08 3.86C22.46 5.7 23.16 7.77 23.16 10.08C23.16 11.24 22.97 12.35 22.59 13.41C22.22 14.47 21.7 15.45 21.03 16.33ZM17.33 12.63L15.67 10.96L15.67 5.63C16.42 6.12 17 6.76 17.39 7.56C17.78 8.36 17.98 9.21 17.98 10.11C17.98 10.56 17.92 11 17.82 11.43C17.71 11.85 17.55 12.25 17.33 12.63L17.33 12.63ZM11.98 7.27L9.69 4.96L11.98 2.67L11.98 7.27Z',
    fill: '#888888',
    fillRule: 'nonzero',
  },
]

{
  /* <svg width="23.160156" height="23.015625" viewBox="0 0 23.1602 23.0156" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"> */
}

export const musicIconPath: pathT[] = [
  {
    d: 'M13.02 20.05L13.02 18.65C14.87 18 16.35 16.89 17.48 15.31C18.61 13.74 19.17 11.97 19.18 10.02C19.18 8.07 18.61 6.31 17.48 4.73C16.35 3.15 14.87 2.04 13.02 1.39L13.02 0C15.23 0.7 17.03 1.97 18.42 3.81C19.81 5.64 20.51 7.71 20.51 10.02C20.51 12.33 19.81 14.4 18.42 16.24C17.03 18.07 15.23 19.34 13.02 20.05L13.02 20.05ZM0 12.72L0 7.39L4.56 7.39L9.33 2.62L9.33 17.49L4.56 12.72L0 12.72ZM13.02 14.47L13.02 5.58C13.78 6.07 14.35 6.71 14.74 7.51C15.13 8.31 15.33 9.16 15.33 10.06C15.33 10.95 15.13 11.79 14.73 12.56C14.33 13.34 13.76 13.98 13.02 14.47Z',
    fill: '#332F36',
    fillRule: 'nonzero',
  },
]

{
  /* <svg width="20.513672" height="20.053223" viewBox="0 0 20.5137 20.0532" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"> */
}

export const musicPlayIconPath: pathT[] = [
  {
    d: 'M91.7598 55.9378C96.4264 58.6321 96.4264 65.3678 91.7598 68.0621L52.3701 90.8037C47.7035 93.498 41.8702 90.1302 41.8702 84.7416L41.8702 39.2584C41.8702 33.8698 47.7035 30.5019 52.3702 33.1962L91.7598 55.9378Z',
    fill: 'white',
  },
]

type IconT = {
  width: number
  height: number
}

export const PlayIcon: FC<IconT> = ({ width, height }) => {
  return (
    <IconSvg path={musicPlayIconPath} viewBoxSize="0 0 124 124" height={height} width={width}>
      <circle cx="62" cy="62" r="62" scale={2} fill="#357EEB" />
    </IconSvg>
  )
}
