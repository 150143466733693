// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body {\n  height: auto;\n}\n\n.App_container__4L7Vo {\n  width: 100vw;\n  width: 100%;\n  height: 100%;\n  margin: 0 auto;\n  overflow: clip;\n  position: relative;\n}\n@media only screen and (min-width: 320px) and (max-width: 375px) {\n  .App_container__4L7Vo {\n    width: 100vw;\n    display: flex;\n    height: 100%;\n  }\n}\n@media only screen and (min-width: 375px) and (max-width: 480px) {\n  .App_container__4L7Vo {\n    width: 100vw;\n    display: flex;\n    height: 100%;\n  }\n}\n@media (min-width: 481px) and (max-width: 767px) {\n  .App_container__4L7Vo {\n    min-width: 481px;\n  }\n}\n@media (min-width: 768px) and (max-width: 1024px) {\n  .App_container__4L7Vo {\n    min-width: 768px;\n  }\n}", "",{"version":3,"sources":["webpack://./src/App.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;;AACA;EACE,YAAA;EACA,WAAA;EACA,YAAA;EAGA,cAAA;EACA,cAAA;EACA,kBAAA;AAAF;AAEE;EAVF;IAYI,YAAA;IACA,aAAA;IACA,YAAA;EAAF;AACF;AAEE;EAjBF;IAmBI,YAAA;IACA,aAAA;IACA,YAAA;EAAF;AACF;AAEE;EAxBF;IAyBI,gBAAA;EACF;AACF;AACE;EA5BF;IA6BI,gBAAA;EAEF;AACF","sourcesContent":["body {\n  height: auto;\n}\n.container {\n  width: 100vw;\n  width: 100%;\n  height: 100%;\n  // display: grid;\n  // max-width: 1920px;\n  margin: 0 auto;\n  overflow: clip;\n  position: relative;\n\n  @media only screen and (min-width: 320px) and (max-width: 375px) {\n    // min-width: 375px;\n    width: 100vw;\n    display: flex;\n    height: 100%;\n  }\n\n  @media only screen and (min-width: 375px) and (max-width: 480px) {\n    // min-width: 375px;\n    width: 100vw;\n    display: flex;\n    height: 100%;\n  }\n\n  @media (min-width: 481px) and (max-width: 767px) {\n    min-width: 481px;\n  }\n\n  @media (min-width: 768px) and (max-width: 1024px) {\n    min-width: 768px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "App_container__4L7Vo"
};
export default ___CSS_LOADER_EXPORT___;
