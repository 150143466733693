import React, {FC, useEffect, useState} from "react";
import {useAppSelector} from "../../store/hooks";
import {authSelector, selectUser} from "../../selectors";
import {useFetchSchoolHeaderQuery} from "../../api/schoolHeaderService";
import {useCreateMeetingMutation, useFetchAllMeetingsQuery, useDeleteMeetingMutation} from "../../api/meetingsService";
import {SchoolMeeting} from "../../types/schoolMeetingsT";
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import {useFetchStudentsGroupQuery} from "../../api/studentsGroupService";
import styles from './meetings.module.scss';
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {Button} from 'components/common/Button/Button'
import {useFetchCoursesQuery} from "../../api/coursesServices";
import MenuItem from "@mui/material/MenuItem";
import {CoursesDataT} from "../../types/CoursesT";
import {setTotalMeetingCount} from "../../store/redux/meetings/meetingSlice";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store/redux/store";
import Timer from "../Timer/Timer";
import { TgMeetingReminders } from "types/tgNotifications";
import {
    useCreateMeetingsRemindersMutation,
    // useDeleteMeetingsRemindersMutation
     } from "api/tgNotificationsServices";
import { number } from "yup";

export const SchoolMeetings: FC = () => {
    const isLogin = useAppSelector(authSelector);
    const totalMeetingCount = useSelector((state: RootState) => state.meetings.totalMeetingCount);
    const schoolName = window.location.href.split('/')[4];
    const headerId = localStorage.getItem('header_id');

    const {role: userRole} = useAppSelector(selectUser);
    // const {data, isSuccess} = useFetchSchoolHeaderQuery(Number(headerId));
    const {data: meetingsData, isSuccess: meetingsSuccess} = useFetchAllMeetingsQuery({schoolName: schoolName});
    const [showAddMeetingForm, setShowAddMeetingForm] = useState(false);
    const [createMeeting, {isLoading, error}] = useCreateMeetingMutation();
    const [createMeetingsReminder] = useCreateMeetingsRemindersMutation();
    // const [deleteMeetingsReminder] = useDeleteMeetingsRemindersMutation();
    const [deleteMeeting, {isLoading: isDeleting, error: deleteError}] = useDeleteMeetingMutation();
    const {data: studentsGroups, isSuccess: groupsSuccess} = useFetchStudentsGroupQuery(schoolName);
    const {data: Courses, isSuccess: coursesSuccess} = useFetchCoursesQuery(schoolName);
    const [selectedCourse, setSelectedCourse] = useState<CoursesDataT | null>(null);
    const [allGroups, setAllGroups] = useState<boolean>(false);
    const [newMeetingData, setNewMeetingData] = useState<SchoolMeeting>({
        id: 0,
        students_groups: [],
        link: '',
        start_date: new Date(),
    });

    const [newMeetingReminder, setNewMeetingReminder] = useState<TgMeetingReminders>({
        daily: false,
        in_three_hours: false,
        ten_minute: false,
        sent: false,
        meeting: newMeetingData.id
    })

    const [showReminderOptions, setShowReminderOptions] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
  if (meetingsSuccess && meetingsData) {
    dispatch(setTotalMeetingCount(meetingsData.length));
  }
}, [meetingsData, meetingsSuccess, dispatch]);

    const handleAddMeeting = () => {
        createMeeting({
            data: newMeetingData,
            schoolName,
        })
        .unwrap()
        .then((meetingResponse) => {
            console.log(meetingResponse.id);

            if (meetingResponse.id) {
                // Здесь вы устанавливаете meeting_id в newMeetingReminder
                const updatedMeetingReminder = { ...newMeetingReminder, meeting: meetingResponse.id };

                createMeetingsReminder({
                    data: updatedMeetingReminder,
                })
                .unwrap()
                .then(() => {
                    dispatch(setTotalMeetingCount(totalMeetingCount + 1));
                    setShowAddMeetingForm(false);
                })
                .catch((error) => {
                    console.error("Error creating meeting reminder", error);
                });
            }
        })
    };

    const handleDeleteMeeting = (meetingId: number, ) => {
        deleteMeeting({id: meetingId, schoolName});
        dispatch(setTotalMeetingCount(totalMeetingCount - 1));
    };

    const handleAddMeetingFormOpen = () => {
    setNewMeetingData({
        ...newMeetingData,
        students_groups: [],
    });
    setShowAddMeetingForm(true);};

    const handleCourseChange = (courseId: number) => {
        setAllGroups(false);
        setSelectedCourse(Courses?.results.find(course => course.course_id === courseId) || null)
    };

    const handleGroupChange = (groupId: number) => {
        setNewMeetingData(prevData => ({
            ...prevData,
            students_groups: [...prevData.students_groups, groupId],
        }));
        setShowReminderOptions(true); // Показываем опции напоминаний после выбора группы
    };

    const handleReminderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target;
        setNewMeetingReminder(prevReminder => ({
            ...prevReminder,
            [name]: checked,
        }));
    };

    const handleAllGroups = (event: React.ChangeEvent<HTMLInputElement>) => {
        const isAll = event.target.checked
        setAllGroups(isAll)
        const groupsByCourse = studentsGroups?.results.filter(group => group.course_id === selectedCourse?.course_id)
        const groupsIds = groupsByCourse?.map(group => group.group_id)
        if (isAll) {
            groupsByCourse?.map(group => {
                    setNewMeetingData((prevData: SchoolMeeting) => ({
                        ...prevData,
                        students_groups: [...prevData.students_groups, group.group_id],
                    }) as SchoolMeeting);
                })
            setShowReminderOptions(true)
        } else {
            setNewMeetingData((prevData: SchoolMeeting) => ({
                ...prevData,
                students_groups: prevData.students_groups.filter((id) => {
                    return !new Set(groupsIds).has(id)
                }),
            }));
        }
    };

    const renderMeetingLinks = () => {
        const dateFormatter = new Intl.DateTimeFormat('ru-RU', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
        });

        if (meetingsSuccess) {
            return (
                    <table className={styles.meetingTable}>
                        <thead>
                        <tr>
                            <th>Ссылка</th>
                            <th>Дата и время</th>
                            <th>Группы</th>
                            <th>Время до старта</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {meetingsData.map((meeting, index) => (
                            <tr key={meeting.id}>
                                <td><a href={meeting.link} target="_blank" rel="noopener noreferrer">{meeting.link}</a>
                                </td>
                                <td>{dateFormatter.format(new Date(meeting.start_date))}</td>
                                <td>{meeting.students_groups.map(groupId => {
                                    const group = studentsGroups?.results.find(g => g.group_id === groupId);
                                    return group ? group.name : '';
                                }).join(', ')}</td>
                                <td><Timer targetDate={new Date(meeting.start_date)} /></td>
                                <td>
                                    <Button onClick={() => handleDeleteMeeting(meeting.id)} text="Удалить"/>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
            );
        }
        return <table className={styles.meetingTable}>
                <tbody>
                    <tr className={styles.table_no_results}>
                        <td>Ничего не найдено</td>
                    </tr>
                </tbody>
            </table>;
    };

    return (
        <div className={styles.wrapper_actions}>
            {isLogin && (
                <>
                    <Button className={styles.generateMeetingButton} onClick={handleAddMeetingFormOpen} text="Добавить видеоконференцию"/>
                    <Dialog open={showAddMeetingForm} onClose={() => setShowAddMeetingForm(false)}
                            PaperProps={{style: {maxHeight: '100vh', maxWidth: '600px', width: '100%'},}}>
                        <DialogTitle>Добавить видеоконференцию</DialogTitle>
                        <DialogContent>
                            <div style={{marginBottom: '1rem', marginTop: '1rem'}}>
                                <TextField
                                    id="datetime-local"
                                    label="Выберите дату и время видеоконференции"
                                    type="datetime-local"
                                    defaultValue={new Date().toISOString().slice(0, 16)}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    fullWidth={true}
                                    onChange={(e) =>
                                        setNewMeetingData({
                                            ...newMeetingData,
                                            start_date: new Date(e.target.value),
                                        })
                                    }
                                />
                            </div>
                            <div style={{marginBottom: '1rem'}}>
                                <TextField
                                    id="link"
                                    label="Ссылка на видеоконференцию"
                                    value={newMeetingData.link}
                                    fullWidth={true}
                                    onChange={(e) =>
                                        setNewMeetingData({...newMeetingData, link: e.target.value})
                                    }
                                />
                            </div>
                            <div>
                                <TextField
                                    id="course"
                                    select
                                    label="Выберите курс"
                                    fullWidth={true}
                                    onChange={(e) => {
                                        const courseId = parseInt(e.target.value);
                                        handleCourseChange(courseId);
                                    }}
                                    value={selectedCourse?.course_id || ""}
                                >
                                    {Courses?.results.map(course => (
                                        <MenuItem key={course.course_id} value={course.course_id}>
                                            {course.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                            {studentsGroups && selectedCourse &&
                            <div>
                                <Checkbox checked={allGroups} onChange={(e) => {handleAllGroups(e)}}/>
                                <span><b>все группы курса</b></span>
                            </div>}
                            {studentsGroups && selectedCourse && studentsGroups.results
                                .filter(group => group.course_id === selectedCourse.course_id)
                                .map(group => {
                                    if (group.course_id === selectedCourse.course_id) {
                                        return (
                                            <div key={group.group_id}>
                                                <Checkbox
                                                    onChange={(e) => {
                                                        const isChecked = e.target.checked;
                                                        if (isChecked) {
                                                            setNewMeetingData((prevData: SchoolMeeting) => ({
                                                                ...prevData,
                                                                students_groups: [...prevData.students_groups, group.group_id],
                                                            }) as SchoolMeeting);
                                                            setShowReminderOptions(true)
                                                        } else {
                                                            setAllGroups(false)
                                                            setNewMeetingData((prevData: SchoolMeeting) => ({
                                                                ...prevData,
                                                                students_groups: prevData.students_groups.filter(
                                                                    (id) => id !== group.group_id
                                                                ),
                                                            }));
                                                        }
                                                    }}
                                                    checked={new Set(newMeetingData.students_groups).has(Number(group.group_id))}
                                                />
                                                {group.name}
                                                <span> (Количество участников: {group.students.length})</span>
                                            </div>
                                        );
                                    }
                                    return null;
                                })}
                            {showReminderOptions && (
                                <div>
                                    <div className={styles.reminders}>Установить телеграм напоминание за:</div>
                                    <Checkbox
                                        name="daily"
                                        onChange={handleReminderChange}
                                        checked={newMeetingReminder.daily}
                                    />
                                    За день
                                    <Checkbox
                                        name="in_three_hours"
                                        onChange={handleReminderChange}
                                        checked={newMeetingReminder.in_three_hours}
                                    />
                                    За три часа
                                    <Checkbox
                                        name="ten_minute"
                                        onChange={handleReminderChange}
                                        checked={newMeetingReminder.ten_minute}
                                    />
                                    За десять минут
                                </div>
                            )}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleAddMeeting} text="Добавить"/>
                            <Button onClick={() => setShowAddMeetingForm(false)} text="Отмена"/>
                        </DialogActions>
                    </Dialog>
                    {renderMeetingLinks()}
                </>
            )}
        </div>
    );
};

export default SchoolMeetings;