export const arrNumber = [
  { number: 1 },
  { number: 2 },
  { number: 3 },
  { number: 4 },
  { number: 5 },
  { number: 6 },
  { number: 7 },
  { number: 8 },
  { number: 9 },
  { number: 10 },
  { number: 20 },
  { number: 30 },
  { number: 40 },
  { number: 50 },
  { number: 60 },
]
export const arrTime = [{ unit: 'минут' }, { unit: 'часов' }, { unit: 'дней' }]
export const coursesSelectLanguage = [
  { value: 'javascript', label: 'Javascript' },
  { value: 'python', label: 'Python' },
  { value: 'css', label: 'CSS' },
  { value: 'typescript', label: 'Typescript' },
  { value: 'java', label: 'Java' },
]

export const homeWorkActions = [
  { actionHomeWork: 'Необходимо получить зачёт' },
  { actionHomeWork: 'Необходимо отправить решение' },
  { actionHomeWork: 'Можно пропустить' },
]

export const testActions = [{ actionTest: 'Можно пропустить' }, { actionTest: 'Необходимо пройти' }, { actionTest: 'Необходимо успешно пройти' }]

export const classesType = [{ type: 'Урок' }, { type: 'Домашнее задание' }, { type: 'Тест' }, { type: 'Вебинар' }]
