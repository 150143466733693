export const checkboxData = [
  // {
  //   id: 'randomQuestions',
  //   name: 'rndQuest',
  //   span1: 'Случайный порядок вопросов',
  //   span2: 'Вопросы будут задаваться в случайном порядк',
  // },
  // {
  //   id: 'shuffleAnswers',
  //   name: 'shuffleAnswer',
  //   span1: 'Перемешивать варианты ответов',
  //   span2: 'Варианты ответов будут отображаться в случайном порядке',
  // },
  {
    id: 'showCorrectAnswer',
    name: 'showCorrect',
    span1: 'Показывать правильные варианты ответов',
    span2: 'После завершения тестирования будут отображены правильные ответы на вопросы',
  },
]
