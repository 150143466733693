import arrowUp from './arrow-up.svg'
import arrowDown from './arrowDown.svg'
import avatar from './avatar.svg'
import blackArrDown from './blackArrowDown.svg'
import close from './close.svg'
import done from './done.svg'
import doneIcon from './done_icon.svg'
import exit from './exit.svg'
import isSecurity from './isecurity.svg'
import logo from './Logo192p.png'
import logOut from './logout.svg'
import mail from './mail.svg'
import message from './message.svg'
import navbar_menu from './navbar_menu.svg'
import noAvatar from './noAvatar.svg'
import phone from './phone.svg'
import settings from './setting 2.svg'
import unSecurity from './unSecurity.svg'
import deleteIcon from './deleteIcon.svg'
import pngwing from './2869065.jpg'
import chatGroup from './chat_group.png'
import chatCourse from './chat_course.png'
import arrowDownTableAZ from './arrowDownTableAZ.svg'
import arrowDownTableZA from './arrowDownTableZA.svg'
import full_logo from './full_logotype.svg'
import logoHeader from './logoHeader.png'
import plus from './plus.png'
import footerlogo from './footerlogo.png'
import callfooter from './callfooter.png'
import mailfooter from './mailfooter.png'
import locationfooter from './locationfooter.png'
import removebg from './removebg.png'
import analytics from './analytics.png'
import chatst from './chatst.png'
import dateInf from './dateInf.png'
import logoHeaderLogin from './logoHeaderLogin.png'
import facebook from './Facebook.png'
import google from './Google.png'
import maillog from './Mail.png'
import leftArrow from './left-arrow.png'
import constructor from './construktor.png'
import hat from './hat.png'
import cam from './cam.png'
import ok from './ok.png'
import chat from './chat.png'
import result from './result.png'
import mobile from './mobile.png'
import comp from './comp.png'
import zimer from '../systemLogo/zapier.png'
import amocmr from '../systemLogo/amocmr.png'
import bitriks from '../systemLogo/bitriks.png'
import googleanalytics from '../systemLogo/googleanalytics.png'
import tbank from '../systemLogo/tbank.png'
import tilda from '../systemLogo/tilda.png'
import vk from '../systemLogo/vk.png'
import wordpress from '../systemLogo/wordpress.png'
import youtube from '../systemLogo/youtube.png'
import zoom from '../systemLogo/zoom.png'
import kassa from '../systemLogo/kassa.png'
import metrika from '../systemLogo/metrika.png'
import rocketfree from './rocketfree.png'
import line from './line.png'
import record from './record.png'
import admin from './Admin.png'
import admin2 from './Admin2.png'
import teacher from './Teacher.png'
import teacher2 from './Teacher2.png'
import student from './Student.png'
import student2 from './Student2.png'

import courseLogo from './courseLogo.svg'

export {
  courseLogo,
  arrowUp,
  arrowDown,
  avatar,
  blackArrDown,
  close,
  done,
  doneIcon,
  exit,
  isSecurity,
  logo,
  logOut,
  logoHeader,
  mail,
  message,
  navbar_menu,
  noAvatar,
  phone,
  settings,
  unSecurity,
  deleteIcon,
  pngwing,
  chatGroup,
  chatCourse,
  arrowDownTableAZ,
  arrowDownTableZA,
  full_logo,
  footerlogo,
  callfooter,
  mailfooter,
  locationfooter,
  removebg,
  analytics,
  chatst,
  dateInf,
  logoHeaderLogin,
  plus,
  facebook,
  google,
  maillog,
  leftArrow,
  constructor,
  hat,
  cam,
  ok,
  chat,
  result,
  mobile,
  comp,
  zimer,
  amocmr,
  bitriks,
  googleanalytics,
  tbank,
  tilda,
  vk,
  wordpress,
  youtube,
  zoom,
  kassa,
  metrika,
  rocketfree,
  line,
  record,
  admin,
  admin2,
  teacher,
  teacher2,
  student,
  student2,
}
