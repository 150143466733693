import { pathT } from '../../../../types/commonComponentsTypes'

export const registrIconPath: pathT[] = [
  {
    d: 'M1 15L15 1M15 15L1 1',
    stroke: '#2E4454',
    strokeWidth: '2',
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
  },
]
