// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/fonts/SFProText/SFProText-Regular.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-family: \"SFPRORegular\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"opentype\");\n}\n.worldSwitcher_container__t7GbR {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: nowrap;\n  align-items: center;\n  gap: 1rem;\n  height: 125px;\n}\n.worldSwitcher_container__t7GbR p {\n  font-family: \"SFPRORegular\";\n  font-weight: 500;\n  font-size: 46px;\n  line-height: 54.89px;\n  color: #332F36;\n}\n.worldSwitcher_container_worlds__0OeFG {\n  font-family: \"SFPRORegular\";\n  color: #357EEB;\n  font-weight: 500;\n  font-size: 46px;\n  line-height: 62px;\n}", "",{"version":3,"sources":["webpack://./src/components/WorldSwitcher/worldSwitcher.module.scss"],"names":[],"mappings":"AAAA;EACI,2BAAA;EACA,+DAAA;AACJ;AAGA;EACI,aAAA;EACA,mBAAA;EACA,iBAAA;EACA,mBAAA;EACA,SAAA;EACA,aAAA;AADJ;AAGI;EACI,2BAAA;EACA,gBAAA;EACA,eAAA;EACA,oBAAA;EACA,cAAA;AADR;AAII;EACI,2BAAA;EACA,cAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;AAFR","sourcesContent":["@font-face {\n    font-family: 'SFPRORegular';\n    src: url(../../assets/fonts/SFProText/SFProText-Regular.ttf) format(\"opentype\");\n  }\n\n\n.container {\n    display: flex;\n    flex-direction: row;\n    flex-wrap: nowrap;\n    align-items: center;\n    gap: 1rem;\n    height: 125px;\n\n    p {\n        font-family: 'SFPRORegular';\n        font-weight: 500;\n        font-size: 46px;\n        line-height: 54.89px;\n        color: #332F36;\n    }\n\n    &_worlds {\n        font-family: 'SFPRORegular';\n        color: #357EEB;\n        font-weight: 500;\n        font-size: 46px;\n        line-height: 62px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "worldSwitcher_container__t7GbR",
	"container_worlds": "worldSwitcher_container_worlds__0OeFG"
};
export default ___CSS_LOADER_EXPORT___;
