import styles from './PWA.module.scss'

export const PWA = () => {
  return (
    <section className={styles.PWA}>
        <p className={styles.PWA_Content}>
          Для установки приложения не телефон следуйте инструкции:
        </p>
        <p>
          <h3 className={styles.PWA_Header}>
            Safari
          </h3>
          <ol className={styles.PWA_Content}>
            <li>
              Зайдите на главную страницу с входом в систему
            </li>
            <li>
              Под доменом сайта перейдите на значок «Поделиться»
            </li>
            <li>
              Пролистайте вниз до кнопки «На экран «Домой»» и нажмите на нее
            </li>
            <li>
              Далее Вам предоставит страницу для подтверждения, в верхнем правом углу нажмите «Добавить»
            </li>
            <li>
              Вас перенаправит на Ваш рабочий стол с уже развернутым и установленным PWA
            </li>
            <li>
              Зайдите в PWA и войдите в систему
            </li>
          </ol>
        </p>
        <p>
          <h3 className={styles.PWA_Header}>
            Chrome
          </h3>
          <ol className={styles.PWA_Content}>
            <li>
              Зайдите на главную страницу с входом в систему
            </li>
            <li>
              Нажмите на кнопку расширений в правом верхнем углу
            </li>
            <li>
              Внизу нажмите кнопку Добавить на главный экран
            </li>
            <li>
              Далее Вам предоставит страницу для подтверждения, нажмите «Добавить»
            </li>
            <li>
              Дождитесь уведомления о завершении загрузки
            </li>
            <li>
              Перейдите на робочий стол
            </li>
            <li>
              Зайдите в PWA и войдите в систему
            </li>
          </ol>
        </p>
        
    </section>
  )
}
