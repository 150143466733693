type ArrType = {
  id: number
  text: string
  type: string
}
export const typeClasses: ArrType[] = [
  {
    id: 1,
    text: 'Обычное занятие может содержать текст, видео, аудио \n' + 'и программный код. Можно прикрепить любые файлы \n' + 'для  скачивания.',
    type: 'lesson',
  },
  {
    id: 2,
    text: 'Ученики могут выполнять домашнее задание \n' + 'и отправлять его на проверку преподавателям.',
    type: 'homework',
  },
  {
    id: 3,
    text: 'Тестирование позволяет создать набор вопросов и вариантов ответов для проверки знаний учеников.',
    type: 'test',
  },
  {
    id: 4,
    text: 'Вебинар позволяет создать занятия в виде онлайн-трансляций по заданному расписанию для каждой группы учеников.',
    type: 'webinar',
  },
]
