import styles from './PersonalDataTreatmentPolicy.module.scss'

export const PersonalDataTreatmentPolicy = () => {
  return (
    <section className={styles.PersonalDataTreatmentPolicy}>
        <p className={styles.PersonalDataTreatmentPolicy_Preamble}>
          УТВЕРЖДЕНО
          <br/>
          приказ № 35 от  20.07.2023 г.
          <br/>
          директор ООО «Оверван»
          <br/>
          Т.В.Андреева
        </p>
        <h1 className={styles.PersonalDataTreatmentPolicy_Header}>
          политика обработки персональных данных
        </h1>
        <ol className={styles.PersonalDataTreatmentPolicy_Content}>
          <li>
            Настоящая политика обработки персональных данных (далее – Политика) определяет основные принципы, цели, условия и способы  обработки персональных данных в Обществе с ограниченной ответственностью «Оверван», УНП 193417722, адрес: 220013, г. Минск, ул. Сурганова, 43-508 (далее – Общество).
          </li>
          <li>
            Политика разработана во исполнение Закона Республики Беларусь от 07.05.2021 г. № 99-З «О защите персональных данных», Закона Республики Беларусь от 10.11.2008 г. № 455-3 «Об информации, информатизации и защите информации», иными законодательными актами Республики Беларусь и локальными нормативными правовыми актами.
          </li>
          <li>
          В настоящей Политике используются термины и их определения согласно действующему законодательству Республики Беларусь, имеющие следующие значения:
          <ul>
            <li>Общество – ООО «Оверван», которое организует и (или) осуществляет обработку персональных данных;
            </li>
            <li>
              Пользователь - дееспособное физическое лицо, достигшее 18-летнего возраста и желающее заказать услуги Общества, оставить комментарий, зарегистрироваться на веб-сайте либо совершить иные действия, предусмотренные функционалом интернет- ресурса Общества.
            </li>
            <li>
              Сайт или Интернет-ресурс Общества -  веб-сайт, размещенный в глобальной компьютерной сети Интернет по адресу: https:// overone.by.
            </li>
            <li>
              Персональные данные - любая информация, относящаяся прямо или косвенно к идентифицированному физическому лицу или физическому лицу, которое может быть идентифицировано.
            </li>
            <li>
              Обработка персональных данных – любое действие или совокупность действий, совершаемые с персональными данными, включая сбор, получение, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.
            </li>
          </ul>
          </li>
          <li>
            Политика является общедоступной и размещается в сети Интернет на Сайте Общества.
          </li>
          <li>
            Настоящая Политика определяет порядок обработки персональных данных пользователей Сайта, условия и принципы обработки персональных данных, права Пользователей и обязанности Общества, сведения о реализуемых мерах по защите обрабатываемых персональных данных.
          </li>
          <li>
            Настоящая Политика действует в отношении всех персональных данных, которые  Общество получает от Пользователей.
          </li>
          <li>
            Пользователь соглашается с настоящей Политикой путем ввода его имени, номера телефона, а при необходимости – адреса электронной почты в специальное поле на Сайте с предложением отправить запрос на консультацию, записаться или совершить иные действия, предусмотренные функционалом Сайта. В случае несогласия с условиями настоящей Политики Пользователь должен немедленно прекратить любое использование Сайта.
          </li>
          <li>
            Согласие Пользователя представляет собой свободное, однозначное, информированное выражение его воли, посредством которого он разрешает обработку своих персональных данных.
          </li>
          <li>
            Согласие Пользователя может быть получено Обществом в письменной форме, в иной электронной форме (п.3.ст.5 Закона Республики Беларусь от 07.05.2021 г. № 99-З «О защите персональных данных»). В иной электронной форме Общество получает согласие посредством проставления Пользователем соответствующей отметки («галочки») в специальное поле на Сайте.
          </li>
          <li>
            При использовании функционала Сайта Пользователь может предоставлять Обществу следующие персональные данные (перечень и виды персональных данных зависят от конкретных функциональных возможностей интернет-ресурса, используемого Пользователем): фамилия, имя, адрес электронной почты, номер контактного телефона, идентификационные данные.
          </li>
          <li>
            Предоставляя свои персональные данные, Пользователь соглашается на их обработку, включая сбор, хранение, обезличивание, передачу третьим лицам в предусмотренных Политикой случаях, Общество в целях предоставления Пользователю рекламной, справочной информации в соответствии с функциональными возможностями Сайта.
          </li>
          <li>
            Предоставленное Пользователем согласие на обработку персональных данных действует со дня предоставления такого согласия до момента достижения целей обработки или отзыва Пользователем согласия, если иное не предусмотрено действующим законодательством Республики Беларусь.
            Пользователь в любое момент может отозвать предоставленное согласие в порядке, установленном настоящей Политикой.
          </li>
          <li>
            Пользователь вправе:
            <table>
              <tr>
                <th>
                  <td>
                    Право
                  </td>
                </th>
                <th>
                <td>
                    Последствия
                  </td>
                </th>
              </tr>
              <tr>
                <td>
                  потребовать от Общества внести изменения, если персональные данные устарели, неполные или неточные
                </td>
                <td>
                  Общество в срок до 15 календарных дней с момента получения запроса внесет изменения в персональные данные Пользователя, если они являются неполными, устаревшими или неточными
                </td>
              </tr>
              <tr>
                <td>
                  на получение информации, касающейся обработки персональных данных
                </td>
                <td>
                  Общество в течение 5 рабочих дней после получения заявления предоставит запрашиваемую Пользователя информацию либо уведомит о причинах отказа в ее предоставлении
                </td>
              </tr>
              <tr>
                <td>
                  один раз в календарный год бесплатно получать информацию о предоставлении своих данных третьим лицам
                </td>
                <td>
                  Общество в срок до 15 календарных дней с момента получения заявления Пользователя предоставит информацию о том, какие персональные данные и кому предоставлялись в течение года, предшествовавшего дате подачи заявления, либо уведомит о причинах отказа в ее предоставлении
                </td>
              </tr>
              <tr>
                <td>
                  отозвать согласие на обработку своих персональных данных
                </td>
                <td>
                  Общество всрок до 15 календарных дней с момента получения отзыва прекратит обработку персональных данных Пользователя за исключением случаев, когда Общество вправе продолжить обработку персональных данных при наличии иных правовых оснований, и уведомит Пользователя об этом
                </td>
              </tr>
              <tr>
                <td>
                  потребовать прекратить обработку своих данных, их удаление
                </td>
                <td>
                  Общество в срок до 15 календарных дней с момента получения запроса прекратит обработку персональных данных Пользователя, осуществит их удаление, за исключением случаев, когда Общество вправе продолжить обработку персональных данных при наличии иных правовых оснований, и уведомит Пользователя об этом
                </td>
              </tr>
              <tr>
                <td>
                  обжаловать действия/бездействий и решения Общества, относящегося к обработке своих персональных данных, в уполномоченный орган по защите прав субъектов персональных данных — Национальный центр защиты персональных данных Республики Беларусь, в порядке, установленном законодательством
                </td>
                <td>
                  Жалоба рассматривается в порядке, установленном законодательством Республики Беларусь об обращениях граждан и юридических лиц.
                </td>
              </tr>
            </table>
          </li>
          <li>
            Для реализации одного или нескольких прав, указанных в п. 13 Политики, Пользователю необходимо направить Обществу (нарочно, лично или почтой) соответствующее заявление одним из следующих способов:
            <ul>
              <li>
                в письменной форме по адресу: Республика Беларусь, 220013, г. Минск, ул. Сурганова, 43-508;
              </li>
              <li>
                в виде электронного документа, содержащего электронную цифровую подпись субъекта персональных данных, на адрес электронной почты it@overone.by.
              </li>
            </ul>
          </li>
          <li>
            Для реализации права на отзыв согласия необходимо направить Обществу соответствующее заявление в порядке, установленном частью 2 п. 14 Политики, либо путем направления электронного сообщения на адрес электронной почты it@overone.by, а в случае если согласие  было предоставлено в иной электронной форме, Пользователь также вправе реализовать право на отзыв согласия, проставив соответствующую отметку («галочку») в специальное поле на Сайте. Право на отзыв согласия не может быть реализовано в случае, когда обработка персональных данных осуществляется на основании договора, заключенного с Пользователем либо является необходимой для выполнения обязанностей (полномочий), предусмотренных законодательными актами.
          </li>
          <li>
            Общество  использует предоставленные Пользователем данные в целях:
            <table>
              <tr>
                <th>
                  <td>
                    Цель
                  </td>
                </th>
                <th>
                  <td>
                    Обрабатываемые персональные данные
                  </td>
                </th>
                <th>
                  <td>
                    Основание
                  </td>
                </th>
                <th>
                  <td>
                    Срок хранения персональных данных
                  </td>
                </th>
              </tr>
              <tr>
                <td>
                  Заключение, исполнение, изменение и расторжение договора с клиентами
                </td>
                <td>
                  Фамилия, имя, отчество (опционально);  
                  номер телефона;
                  адрес электронной почты (опционально);
                  адрес доставки (опционально);
                  адрес места жительства (опционально);
                  запись телефонного разговора (опционально).
                </td>
                <td>
                  ч. 14  ст. 6 Закона Республики Беларусь от 07.05.2021 N 99-З «О защите персональных данных»
                </td>
                <td>
                  3 года после окончания срока действия договора, при проведении налоговыми органами проверки соблюдения налогового законодательства, если налоговыми органами проверка соблюдения налогового законодательства не проводилась — 10 лет после окончания срока действия договора —  Постановления Министерства юстиции Республики Беларусь от 24.05.2012 N 140 «О перечне типовых документов».
                </td>
              </tr>
              <tr>
                <td>
                  Для проведения рассылок маркетингового, рекламного, новостного характера, анализа данных
                </td>
                <td>
                  Фамилия, имя, отчество (опционально);
                  номер телефона и/или адрес электронной почты (опционально)
                </td>
                <td>
                  Согласие
                </td>
                <td>
                  Срок согласия на обработку персональных данных: 3 года
                  с момента
                  получения согласия
                </td>
              </tr>
              <tr>
                <td>
                  Для проведения маркетинговых и (или) рекламных активностей (акций, конкурсов и других мероприятий), идентификации Пользователя в них, анализа данных
                </td>
                <td>
                  Фамилия, имя, отчество (опционально);
                  паспортные данные (опционально);
                  номер телефона;
                  адрес электронной почты;
                  дата рождения
                </td>
                <td>
                  Согласие
                </td>
                <td>
                  Срок согласия на обработку персональных данных: 3 года
                  с момента
                  получения согласия
                </td>
              </tr>
            </table>
          </li>
          <li>
            Общество не проверяет данные, представленные или указанные Пользователем и исходит из того, что при представлении персональных данных Пользователь:
            <ul>
              <li>
                является дееспособным лицом. В случае недееспособности лица, использующего Сайт, согласие на обработку персональных данных предоставляется законным представителем;
              </li>
              <li>
                указывает достоверную информацию о себе (либо о представляемом им недееспособном лице);
              </li>
              <li>
                самостоятельно поддерживает представленные персональные данные в актуальном состоянии;
              </li>
              <li>
                осознает, что информация на Сайте, размещаемая Пользователем, может становиться доступной для других лиц.
              </li>
            </ul>
          </li>
          <li>
            Общество обрабатывает персональные данные Пользователей на основе следующих принципов:
            <ul>
              <li>
                соблюдения действующего законодательства Республики Беларусь; 
              </li>
              <li>
                недопущения обработки персональных данных, несовместимой с целями сбора или хранения полученных от Пользователя данных; 
              </li>
              <li>
                соответствия содержания и объема обрабатываемых персональных данных целям их обработки;
              </li>
              <li>
                обеспечение точности, достаточности и актуальности персональных данных по отношению к целям их обработки;
              </li>
              <li>
                уничтожение или обезличивание персональных данных в целях недопущения их раскрытия при достижении целей обработки данных, утраты необходимости такой обработки или при получении от Пользователя требования об уничтожении персональных данных либо поступлении заявления об отзыве согласия на обработку персональных данных.
              </li>
            </ul>
          </li>
          <li>
            Общество при обработке персональных данных принимает все необходимые, организационные и технические меры для защиты персональных данных от неправомерного доступа к ним, а также от иных неправомерных действий в отношении персональных данных Пользователей.
            Обработка персональных данных Пользователей осуществляется Обществом с учетом следующего:
            <ul>
              <li>
                обработка персональных данных Пользователей осуществляется Обществом с использованием баз данных на территории Республики Беларусь; 
              </li>
              <li>
                обработка        персональных данных	Пользователей   осуществляется	как
                с использованием  автоматизированных средств, так и без их использования; 
              </li>
              <li>
                Общество обязуется не передавать полученную от Пользователя информацию третьим лицам, кроме случаев, специально оговоренных в настоящей Политике.
              </li>
            </ul>
          </li>
          <li>
            Трансграничная передача персональных данных Пользователей Обществом не осуществляется.
          </li>
          <li>
            Передача персональных данных Пользователей третьим лицам - партнерам Общества при необходимости осуществляется на основании согласия Пользователей в целях исполнения обязательств перед Пользователями.
          </li>
          <li>
            В случае необходимости передачи персональных данных Пользователей третьим лицам - партнерам Общества эти лица обязуются сохранять в тайне, не раскрывать и не распространять персональные данные без согласия Пользователя, если иное не предусмотрено действующим законодательством Республики Беларусь и настоящей Политикой.
          </li>
          <li>
            Общество самостоятельно определяет перечень третьих лиц – партнеров Общества и доводит его до сведения Пользователей любыми доступными способами, в том числе путем публикации на Сайте.
          </li>
          <li>
            Хранение персональных данных Пользователей осуществляется на электронных носителях, а для целей исполнения обязательств перед Пользователями может осуществляться  на материальных носителях после извлечения персональных данных.
          </li>
          <li>
          Хранение персональных данных осуществляется Обществом в пределах срока, объективно  необходимого для исполнения обязательств перед Пользователями, и определяется следующими событиями:
          <ul>
            <li>
              до момента уничтожения персональных данных Обществом в связи с поступлением от Пользователя требования об уничтожении персональных данных или отзыве согласия на их обработку;
            </li>
            <li>
              до момента истечения срока действия согласия Пользователя.
            </li>
          </ul>
          </li>
          <li>
            Общество имеет право хранить персональные данные Пользователя, кроме случаев получения требования об уничтожении данных или отзыва согласия на обработку данных.
          </li>
          <li>
            При необходимости передачи персональных данных Пользователей третьим лицам –  партнерам   Общества в случаях, прямо предусмотренных настоящей Политикой, такая передача осуществляется с соблюдением следующих условий:
            <ul>
              <li>
                третье лицо – партнер Общества обеспечивает конфиденциальность персональных данных при их обработке и использовании и обязуется не раскрывать персональные данные Пользователей иным лицам, а равно не распространять персональные данные Пользователей без их согласия;
              </li>
              <li>
                третье лицо – партнер Общества гарантирует соблюдение следующих мер по обеспечению безопасности персональных данных Пользователей при их обработке: использование средств защиты информации, обнаружение и фиксация фактов несанкционированного доступа к персональным данным Пользователей и принятие мер по их восстановлению, ограничение доступа к персональным данным Пользователей, контроль и оценка эффективности применяемых мер по обеспечению безопасности персональных данных Пользователей, иных мер, предусмотренных действующим законодательством Республики Беларусь.
              </li>
            </ul>
          </li>
          <li>
            Не считается нарушением обязательств, предусмотренных настоящей Политикой, передача Обществом информации в соответствии с обоснованными и применимыми требованиями законодательства Республики Беларусь.
          </li>
          <li>
            Общество вправе использовать технологию «cookies» («куки»). Cookies - данные, которые автоматически передаются Обществу в процессе использования Сайта с помощью установленного на устройстве Пользователя программного обеспечения, в том числе IP-адрес, географическое местоположение, информация о браузере и виде операционной системы устройства Пользователя, технические характеристики оборудования и программного обеспечения, используемых Пользователем, дата и время доступа к Сайту.
          </li>
          <li>
            Файлы «сookies» не содержат конфиденциальную информацию. Файлы «cookies» используются для того, чтобы запоминать предпочтения и настройки Пользователя, а также для сбора аналитических данных о посещениях Сайта. Использование Сайта означает, что Пользователь соглашается с использованием всех файлов «cookies» и аналитических данных о посещениях Сайта, а также на их передачу третьим лицам.
          </li>
          <li>
            Общество вправе вносить изменения в настоящую Политику в любое время. Актуальный текст настоящей Политики размещается на Сайте.
          </li>
          <li>
            Продолжение пользования Сайтом или его сервисами после публикации новой редакции настоящей Политики означает принятие ее условий Пользователем. В случае несогласия с условиями настоящей Политики Пользователь должен немедленно прекратить использование Сайта и его сервисов.
          </li>
          <li>
            Лицом, ответственным за осуществление внутреннего контроля за обработкой персональных данных в Обществе является директор Общества.
          </li>
          <li>
            Все вопросы по настоящей Политике и  обработке персональных  данных            направляются на адрес электронной почты Общества: it@overone.by
          </li>
          <li>
            Сведения об Обществе:
            <br/>
            Общество с ограниченной ответственностью «Оверван»
            <br/>
            220013, г. Минск ул. Сурганова 43-508
            <br/>
            УНП 193417722
            <br/>
            E-mail: it@overone.by
          </li>
        </ol>
    </section>
  )
}
