// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AddNewFolderModal_wrapper__CzyA4 {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.AddNewFolderModal_container__XIG-2 {\n  width: 500px;\n  height: auto;\n  border-radius: 0.7rem;\n  background: white;\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n  padding: 1.5rem 1.5rem;\n}\n.AddNewFolderModal_container_closeModal__XoFZn {\n  background: transparent;\n  width: 30px;\n  height: 30px;\n  border-radius: 5px;\n  border: none;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  color: #e0dced;\n}\n.AddNewFolderModal_container_closeModal__XoFZn:hover {\n  color: #4d5766;\n  transition: color 0.3s;\n}", "",{"version":3,"sources":["webpack://./src/components/Modal/AddFolderModal/AddNewFolderModal.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACF;;AAEA;EACE,YAAA;EACA,YAAA;EACA,qBAAA;EACA,iBAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;EACA,sBAAA;AACF;AACE;EACE,uBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,YAAA;EACA,eAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,cAAA;AACJ;AACI;EACE,cAAA;EACA,sBAAA;AACN","sourcesContent":[".wrapper {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.container {\n  width: 500px;\n  height: auto;\n  border-radius: 0.7rem;\n  background: white;\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n  padding: 1.5rem 1.5rem;\n\n  &_closeModal {\n    background: transparent;\n    width: 30px;\n    height: 30px;\n    border-radius: 5px;\n    border: none;\n    cursor: pointer;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    color: #e0dced;\n\n    &:hover {\n      color: #4d5766;\n      transition: color 0.3s;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "AddNewFolderModal_wrapper__CzyA4",
	"container": "AddNewFolderModal_container__XIG-2",
	"container_closeModal": "AddNewFolderModal_container_closeModal__XoFZn"
};
export default ___CSS_LOADER_EXPORT___;
