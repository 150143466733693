import { IconSvg } from '../../common/IconSvg/IconSvg'
import * as pathes from './svgIconsPath'

export const BLOCK_TYPES = [
  {
    label: <IconSvg width={13} height={12} viewBoxSize="0 0 13 12" path={pathes.labelHeaderOnePath} />,
    style: 'header-one',
  },
  {
    label: <IconSvg width={14} height={12} viewBoxSize="0 0 14 12" path={pathes.labelHeaderTwoPath} />,
    style: 'header-two',
  },
  {
    label: <IconSvg width={14} height={12} viewBoxSize="0 0 14 12" path={pathes.labelHeaderThreePath} />,
    style: 'header-three',
  },
  {
    label: <IconSvg width={12} height={12} viewBoxSize="0 0 12 12" path={pathes.labelUnorderedListItemPath} />,
    style: 'unordered-list-item',
  },
  {
    label: <IconSvg width={12} height={12} viewBoxSize="0 0 12 12" path={pathes.labelOrderedListItemPath} />,
    style: 'ordered-list-item',
  },
  {
    label: <IconSvg width={16} height={12} viewBoxSize="0 0 16 12" path={pathes.labelCodeBlockPath} />,
    style: 'code-block',
  },
]

export const INLINE_STYLES = [
  { label: <IconSvg width={20} height={20} viewBoxSize="0 0 24 24" path={pathes.boldIconPath} />, style: 'BOLD' },
  { label: <IconSvg width={20} height={20} viewBoxSize="0 0 24 24" path={pathes.italicIconPath} />, style: 'ITALIC' },
  { label: <IconSvg width={20} height={20} viewBoxSize="0 0 24 24" path={pathes.underlineIconPath} />, style: 'UNDERLINE' },
  { label: <IconSvg width={14} height={16} viewBoxSize="0 0 448 510" path={pathes.monoIconPath} />, style: 'CODE' },
  {
    label: (
      <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
        <path d="M161.3 144c3.2-17.2 14-30.1 33.7-38.6c21.1-9 51.8-12.3 88.6-6.5c11.9 1.9 48.8 9.1 60.1 12c17.1 4.5 34.6-5.6 39.2-22.7s-5.6-34.6-22.7-39.2c-14.3-3.8-53.6-11.4-66.6-13.4c-44.7-7-88.3-4.2-123.7 10.9c-36.5 15.6-64.4 44.8-71.8 87.3c-.1 .6-.2 1.1-.2 1.7c-2.8 23.9 .5 45.6 10.1 64.6c4.5 9 10.2 16.9 16.7 23.9H32c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H270.1c-.1 0-.3-.1-.4-.1l-1.1-.3c-36-10.8-65.2-19.6-85.2-33.1c-9.3-6.3-15-12.6-18.2-19.1c-3.1-6.1-5.2-14.6-3.8-27.4zM348.9 337.2c2.7 6.5 4.4 15.8 1.9 30.1c-3 17.6-13.8 30.8-33.9 39.4c-21.1 9-51.7 12.3-88.5 6.5c-18-2.9-49.1-13.5-74.4-22.1c-5.6-1.9-11-3.7-15.9-5.4c-16.8-5.6-34.9 3.5-40.5 20.3s3.5 34.9 20.3 40.5c3.6 1.2 7.9 2.7 12.7 4.3l0 0 0 0c24.9 8.5 63.6 21.7 87.6 25.6l0 0 .2 0c44.7 7 88.3 4.2 123.7-10.9c36.5-15.6 64.4-44.8 71.8-87.3c3.6-21 2.7-40.4-3.1-58.1H335.1c7 5.6 11.4 11.2 13.9 17.2z" />
      </svg>
    ),
    style: 'STRIKETHROUGH',
  },
]
