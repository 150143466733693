import { IconSvg } from "components/common/IconSvg/IconSvg"
import { peopleIconPath } from "config/commonSvgIconsPath"

export const PeopleIconSvg = () => {
  return (
    <IconSvg path={peopleIconPath} width={24} height={24} viewBoxSize="0 0 24 24">
      <defs>
        <linearGradient id="paint0_linear_14245_85147" x1="14.5703" y1="4.59001" x2="21.2303" y2="4.59001" gradientUnits="userSpaceOnUse">
          <stop offset="0.135" stopColor="#3170E7" />
          <stop offset="1" stopColor="#7A90F7" />
        </linearGradient>
        <linearGradient id="paint1_linear_14245_85147" x1="16.1875" y1="12.0168" x2="22.7185" y2="12.0168" gradientUnits="userSpaceOnUse">
          <stop offset="0.135" stopColor="#3170E7" />
          <stop offset="1" stopColor="#7A90F7" />
        </linearGradient>
        <linearGradient id="paint2_linear_14245_85147" x1="2.73047" y1="4.58" x2="9.39047" y2="4.58" gradientUnits="userSpaceOnUse">
          <stop offset="0.135" stopColor="#3170E7" />
          <stop offset="1" stopColor="#7A90F7" />
        </linearGradient>
        <linearGradient id="paint3_linear_14245_85147" x1="1.25" y1="12.0168" x2="7.78104" y2="12.0168" gradientUnits="userSpaceOnUse">
          <stop offset="0.135" stopColor="#3170E7" />
          <stop offset="1" stopColor="#7A90F7" />
        </linearGradient>
        <linearGradient id="paint4_linear_14245_85147" x1="8.57031" y1="12.06" x2="15.2303" y2="12.06" gradientUnits="userSpaceOnUse">
          <stop offset="0.135" stopColor="#3170E7" />
          <stop offset="1" stopColor="#7A90F7" />
        </linearGradient>
        <linearGradient id="paint5_linear_14245_85147" x1="7.28125" y1="19.49" x2="16.7213" y2="19.49" gradientUnits="userSpaceOnUse">
          <stop offset="0.135" stopColor="#3170E7" />
          <stop offset="1" stopColor="#7A90F7" />
        </linearGradient>
      </defs>
    </IconSvg>
  )
}
