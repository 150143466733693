// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".avatar_avatar__2pM2u {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  text-transform: uppercase;\n  background: #d3f2f8;\n  color: #72b0bc;\n  width: 100%;\n  height: 100%;\n  flex-shrink: 0;\n  font-size: inherit;\n  font-weight: inherit;\n  line-height: normal;\n  border-radius: 50%;\n  -webkit-border-radius: 50%;\n  -moz-border-radius: 50%;\n  -ms-border-radius: 50%;\n  -o-border-radius: 50%;\n}\n.avatar_avatar__2pM2u img {\n  width: 100%;\n  height: 100%;\n  object-fit: contain;\n  display: block;\n  border-radius: 50%;\n  -webkit-border-radius: 50%;\n  -moz-border-radius: 50%;\n  -ms-border-radius: 50%;\n  -o-border-radius: 50%;\n}", "",{"version":3,"sources":["webpack://./src/components/Avatar/avatar.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,yBAAA;EACA,mBAAA;EACA,cAAA;EACA,WAAA;EACA,YAAA;EACA,cAAA;EACA,kBAAA;EACA,oBAAA;EACA,mBAAA;EACA,kBAAA;EACA,0BAAA;EACA,uBAAA;EACA,sBAAA;EACA,qBAAA;AACF;AACE;EACE,WAAA;EACA,YAAA;EACA,mBAAA;EACA,cAAA;EACA,kBAAA;EACA,0BAAA;EACA,uBAAA;EACA,sBAAA;EACA,qBAAA;AACJ","sourcesContent":[".avatar {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  text-transform: uppercase;\n  background: #d3f2f8;\n  color: #72b0bc;\n  width: 100%;\n  height: 100%;\n  flex-shrink: 0;\n  font-size: inherit;\n  font-weight: inherit;\n  line-height: normal;\n  border-radius: 50%;\n  -webkit-border-radius: 50%;\n  -moz-border-radius: 50%;\n  -ms-border-radius: 50%;\n  -o-border-radius: 50%;\n\n  & img {\n    width: 100%;\n    height: 100%;\n    object-fit: contain;\n    display: block;\n    border-radius: 50%;\n    -webkit-border-radius: 50%;\n    -moz-border-radius: 50%;\n    -ms-border-radius: 50%;\n    -o-border-radius: 50%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"avatar": "avatar_avatar__2pM2u"
};
export default ___CSS_LOADER_EXPORT___;
