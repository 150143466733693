import { classesSettingSvgIcon, addCommentsIcon, lessonsTypeSvgIcon, clearTimer, arrowDownTimer, addPictures, grabIcon} from 'constants/iconSvgConstants';

export const lessonIcon = [{ d: lessonsTypeSvgIcon.lesson, fill: '#D4D7DD' }];

export const deleteIconPath = [{ d: classesSettingSvgIcon.deleteIcon, fill: '#D4D7DD' }];
export const addCommentsIconPath = [{ d: addCommentsIcon,  stroke: "#D4D7DD", strokeWidth: "1.5"}];
export const clearTimerPath = [{ d: clearTimer, fill: '#D4D7DD' }];
export const arrowDownPath = [{ d: classesSettingSvgIcon.arrowDown, fill: '#D4D7DD' }];
export const arrowDownTimerIconPath = [{ d: arrowDownTimer, stroke:"#D4D7DD", strokeWidth:"2"}];
export const addPictureIconPath = [{ d: addPictures, fill:"#D4D7DD",}];
export const grabIconPath = [{ d: grabIcon, stroke:"#D4D7DD", strokeWidth:"1.5"}];

