export { authReduce } from './users/slice'
export { sectionsReduce } from './modules/slice'
export { filtersReducer } from './filters/slice'
export { chatReducer } from './chats/slice'
export { schoolReducer } from './school/schoolSlice'
export { schoolIdReducer } from './school/schoolIdSlice'
export { headerIdReducer } from './school/headerIdSlice'
export { modulesReduce } from './modules/modules'
export { unreadReducer } from './chats/unreadSlice'
export { chatsReducer } from './chats/chatsSlice'
export { userProfileReducer } from './users/profileSlice'
export { tariffReducer } from './tariff/tariffSlice'
export { chatgptReducer } from './chatgpt/store'
export { unreadAppealsReducer } from './info/unreadAppealsSlice'
export { meetingReducer } from './meetings/meetingSlice'
export { bonusReducer } from './bonuses/bonusSlice'

export { landingReducer } from './landing/constructorSlice'
export { schoolProgressReducer } from './newSchoolProgression/slice'
