import { combineReducers, configureStore, isRejectedWithValue, PreloadedState } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import * as services from '../../api/index'
import * as slices from './index'
import { modulesReduce } from './modules/modules'
import errorMiddleware from '../DomainErrorMiddleware'



export const rootReducer = combineReducers({
  [services.refreshApi.reducerPath]: services.refreshApi.reducer,
  [services.authApi.reducerPath]: services.authApi.reducer,
  [services.userProgressService.reducerPath]: services.userProgressService.reducer,
  [services.userLoginService.reducerPath]: services.userLoginService.reducer,
  [services.coursesServices.reducerPath]: services.coursesServices.reducer,
  [services.modulesServices.reducerPath]: services.modulesServices.reducer,
  [services.schoolHeaderService.reducerPath]: services.schoolHeaderService.reducer,
  [services.schoolService.reducerPath]: services.schoolService.reducer,
  [services.profileService.reducerPath]: services.profileService.reducer,
  [services.studentsGroupService.reducerPath]: services.studentsGroupService.reducer,
  [services.courseStatService.reducerPath]: services.courseStatService.reducer,
  [services.homeworksStatsService.reducerPath]: services.homeworksStatsService.reducer,
  [services.userHomeworkService.reducerPath]: services.userHomeworkService.reducer,
  [services.userRegisterService.reducerPath]: services.userRegisterService.reducer,
  [services.questionsAndAnswersService.reducerPath]: services.questionsAndAnswersService.reducer,
  [services.filesService.reducerPath]: services.filesService.reducer,
  [services.chatsService.reducerPath]: services.chatsService.reducer,
  [services.getSchoolService.reducerPath]: services.getSchoolService.reducer,
  [services.studentsTableService.reducerPath]: services.studentsTableService.reducer,
  [services.userTestService.reducerPath]: services.userTestService.reducer,
  [services.getAllUsers.reducerPath]: services.getAllUsers.reducer,
  [services.userAccessService.reducerPath]: services.userAccessService.reducer,
  [services.lessonAccessService.reducerPath]: services.lessonAccessService.reducer,
  [services.schoolCreationService.reducerPath]: services.schoolCreationService.reducer,
  [services.tariffPlanService.reducerPath]: services.tariffPlanService.reducer,
  [services.videoFilesService.reducerPath]: services.videoFilesService.reducer,
  [services.tariffService.reducerPath]: services.tariffService.reducer,
  [services.subscriptionService.reducerPath]: services.subscriptionService.reducer,
  [services.forgotPassword.reducerPath]: services.forgotPassword.reducer,
  [services.tariffPlan.reducerPath]: services.tariffPlan.reducer,
  [services.CoursesPageService.reducerPath]: services.CoursesPageService.reducer,
  [services.chatgptService.reducerPath]: services.chatgptService.reducer,
  [services.blocksService.reducerPath]: services.blocksService.reducer,
  [services.catalogService.reducerPath]: services.catalogService.reducer,
  [services.prodamusService.reducerPath]: services.prodamusService.reducer,
  [services.meetingService.reducerPath]: services.meetingService.reducer,
  [services.tgNotificationsService.reducerPath]: services.tgNotificationsService.reducer,
  [services.courseLandingServices.reducerPath]: services.courseLandingServices.reducer,
  [services.domainService.reducerPath]: services.domainService.reducer,
  [services.schoolBonusService.reducerPath]: services.schoolBonusService.reducer,
  [services.ratingService.reducerPath]: services.ratingService.reducer,

  user: slices.authReduce,
  sections: slices.sectionsReduce,
  filters: slices.filtersReducer,
  chat: slices.chatReducer,
  school: slices.schoolReducer,
  schoolId: slices.schoolIdReducer,
  headerId: slices.headerIdReducer,
  tariff: slices.tariffReducer,
  unread: slices.unreadReducer,
  unreadAppeals: slices.unreadAppealsReducer,
  chats: slices.chatsReducer,
  userProfile: slices.userProfileReducer,
  modules: modulesReduce,
  meetings: slices.meetingReducer,
  bonuses: slices.bonusReducer,
  landing: slices.landingReducer,
  schoolProgress: slices.schoolProgressReducer,
})

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user', 'sections', 'filters', 'school', 'schoolId', 'headerId', 'landing', 'schoolProgress'],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: persistedReducer,
    preloadedState,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({ serializableCheck: false, immutableCheck: false }).concat(
        services.refreshApi.middleware,
        services.authApi.middleware,
        services.coursesServices.middleware,
        services.userLoginService.middleware,
        services.modulesServices.middleware,
        services.schoolHeaderService.middleware,
        services.schoolService.middleware,
        services.profileService.middleware,
        services.studentsGroupService.middleware,
        services.courseStatService.middleware,
        services.homeworksStatsService.middleware,
        services.userHomeworkService.middleware,
        services.userRegisterService.middleware,
        services.questionsAndAnswersService.middleware,
        services.filesService.middleware,
        services.chatsService.middleware,
        services.userProgressService.middleware,
        services.studentsTableService.middleware,
        services.getSchoolService.middleware,
        services.userTestService.middleware,
        services.getAllUsers.middleware,
        services.userAccessService.middleware,
        services.lessonAccessService.middleware,
        services.schoolCreationService.middleware,
        services.tariffPlanService.middleware,
        services.videoFilesService.middleware,
        services.forgotPassword.middleware,
        services.tariffService.middleware,
        services.subscriptionService.middleware,
        services.tariffPlan.middleware,
        services.CoursesPageService.middleware,
        services.chatgptService.middleware,
        services.blocksService.middleware,
        services.catalogService.middleware,
        services.prodamusService.middleware,
        services.meetingService.middleware,
        services.schoolBonusService.middleware,
        services.tgNotificationsService.middleware,
        services.courseLandingServices.middleware,
        services.domainService.middleware,
        services.ratingService.middleware,
        errorMiddleware,
      ),
  })
}

export const store = setupStore()
export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']
