// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".checkbox_ball_switch__hX\\+jS {\n  width: 40px;\n  height: 22px;\n  background-color: #E3E3E3;\n  display: flex;\n  justify-content: flex-start;\n  border-radius: 10px;\n  border: 1px solid #E3E3E3;\n  padding: 2.5px;\n  position: relative;\n  cursor: pointer;\n}\n\n.checkbox_ball_switch__hX\\+jS[data-isOn=true] {\n  justify-content: flex-end;\n  background-color: #ffffff;\n}\n.checkbox_ball_switch__hX\\+jS[data-isOn=true] .checkbox_ball_handle__-9y1P {\n  background-color: #357EEB;\n}\n\n.checkbox_ball_handle__-9y1P {\n  width: 15px;\n  height: 15px;\n  background-color: #BEBEBE;\n  border-radius: 50%;\n}", "",{"version":3,"sources":["webpack://./src/components/common/CheckboxBall/checkbox_ball.module.scss","webpack://./src/scss/variable.scss"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,YAAA;EACA,yBAAA;EACA,aAAA;EACA,2BAAA;EACA,mBCIW;EDHX,yBAAA;EACA,cAAA;EACA,kBAAA;EACA,eAAA;AADF;;AAIA;EACE,yBAAA;EACA,yBAAA;AADF;AAEE;EACA,yBAAA;AAAF;;AAIA;EACE,WAAA;EACA,YAAA;EACA,yBAAA;EACA,kBCdW;ADab","sourcesContent":["@import '../../../scss/variable';\n\n.switch {\n  width: 40px;\n  height: 22px;\n  background-color: #E3E3E3;\n  display: flex;\n  justify-content: flex-start;\n  border-radius: $bord-rad10;\n  border: 1px solid #E3E3E3;\n  padding: 2.5px;\n  position: relative;\n  cursor: pointer;\n}\n\n.switch[data-isOn='true'] {\n  justify-content: flex-end;\n  background-color: #ffffff;\n  .handle{\n  background-color: #357EEB;\n  }\n}\n\n.handle {\n  width: 15px;\n  height: 15px;\n  background-color: #BEBEBE;\n  border-radius: $bord-rad50;\n}\n","$box-shadow: 0 0 8px #d9d9d9;\n$font-color: #646f74;\n$font-title: #4d5766;\n$backgroundCheckBox: #e0dced;\n$font-family-inter: 'Inter', sans-serif;\n$font-color-rose: #ba75ff;\n$fs11: 11px;\n$fs12: 12px;\n$fs14: 14px;\n$fs16: 16px;\n$color-white: #ffffff;\n$bord-rad7: 7px;\n$bord-rad10: 10px;\n$bord-rad50: 50%;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"switch": "checkbox_ball_switch__hX+jS",
	"handle": "checkbox_ball_handle__-9y1P"
};
export default ___CSS_LOADER_EXPORT___;
